import TextField from "@mui/material/TextField"
import MaskInput from "app/components/shared/MaskInput"
import useFormCtx from "app/hooks/useFormCtx"
import { useField } from "formik"

//todo: to see how to define prop-types for this component

const JumboTextField = (props) => {
  const { readOnly } = useFormCtx()
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ""

  const maskProps = {}

  if (props.mask) {
    maskProps.InputProps = {
      inputComponent: MaskInput,
      inputProps: {
        mask: props.mask,
        definitions: {
          A: /[a-zA-Z0-9]/,
        },
      },
      readOnly,
    }
  }

  return (
    <TextField
      {...props}
      InputProps={{
        readOnly,
        ...props.InputProps,
      }}
      {...field}
      helperText={errorText}
      error={!!errorText}
      {...maskProps}
    />
  )
}

export default JumboTextField
