import * as React from "react"
import { experimentalStyled as styled } from "@mui/material/styles"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import JumboDemoCard from "@jumbo/components/JumboDemoCard"
import code from "../Grids/demo-code/responsive-grid.txt"
import Div from "@jumbo/shared/Div"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const ResponsiveGrid = () => {
  return (
    <JumboDemoCard title={"Responsive"} demoCode={code}>
      <Div sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {Array.from(Array(6)).map((_, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Item>xs=2</Item>
            </Grid>
          ))}
        </Grid>
      </Div>
    </JumboDemoCard>
  )
}
export default ResponsiveGrid
