import { ExpandMore } from "@mui/icons-material"
import {
  Button,
  Card,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { Formik } from "formik"
import { useState } from "react"

const filters = [
  {
    field: "type",
    label: "Tipo de Serviço",
    options: [
      { label: "Frete", value: "shipping" },
      {
        label: "Mudança",
        value: "move",
      },
    ],
  },
  {
    field: "userRole",
    label: "Tipo de Usuário",
    options: [
      { label: "Cliente", value: "client" },
      {
        label: "Motorista",
        value: "driver",
      },
    ],
  },
  {
    field: "status",
    label: "Status de Serviço",
    options: [
      { label: "Em andamento", value: "inProgress" },
      {
        label: "Solicitado",
        value: "scheduled",
      },
      {
        label: "Finalizado",
        value: "done",
      },
      {
        label: "Cancelado",
        value: "cancelled",
      },
    ],
  },
  {
    field: "paymentMethod",
    label: "Meio de Pagamento",
    options: [
      { label: "Pix", value: "pix" },
      {
        label: "Cartão de Crédito",
        value: "creditCard",
      },
    ],
  },
  {
    field: "paymentStatus",
    label: "Status do Pagamento",
    options: [
      { label: "Processando", value: "pending" },
      {
        label: "Reembolsado",
        value: "refunded",
      },
      {
        label: "Pago",
        value: "approved",
      },
      {
        label: "Recusado",
        value: "reproved",
      },
    ],
  },
]

const initialValues = filters.reduce((prev, curr) => {
  if (!prev[curr.field]) {
    return { ...prev, [curr.field]: "" }
  }
  return prev
}, {})

const HistoryFilters = ({ onChangeFilters, values }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Card sx={{ px: 2, pt: 2 }}>
      <Stack
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
        gap={2}
      >
        <Divider
          onClick={() => setExpanded((prev) => !prev)}
          sx={{
            ".MuiDivider-wrapper": {
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <Typography>Filtros</Typography>
          <ExpandMore
            sx={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "300ms transform ease-in-out",
            }}
          />
        </Divider>
        <Collapse in={expanded}>
          <Formik
            initialValues={values ?? initialValues}
            onSubmit={(values) => {
              onChangeFilters(values)
            }}
          >
            {({ values, setFieldValue, submitForm, resetForm }) => (
              <Grid container spacing={2} sx={{ py: 2 }}>
                {filters.map((filter) => (
                  <Grid item xs={12} sm={6} md={2.4} key={filter.field}>
                    <FormControl fullWidth>
                      <InputLabel id={`${filter.field}-label-id`}>
                        {filter.label}
                      </InputLabel>
                      <Select
                        defaultValue=""
                        labelId={`${filter.field}-label-id`}
                        id={`${filter.field}-select`}
                        value={values[filter.field]}
                        label={filter.label}
                        onChange={(ev) =>
                          setFieldValue(filter.field, ev.target.value)
                        }
                      >
                        <MenuItem value="">Nenhum</MenuItem>
                        {filter.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", gap: 2 }}
                  justifyContent="flex-end"
                >
                  <Button
                    onClick={() =>
                      resetForm({
                        values: initialValues,
                      })
                    }
                  >
                    Limpar
                  </Button>
                  <Button onClick={submitForm} variant="contained">
                    Aplicar
                  </Button>
                </Grid>
              </Grid>
            )}
          </Formik>
        </Collapse>
      </Stack>
    </Card>
  )
}

export default HistoryFilters
