import { getAssetPath } from "../../../../utils/appHelpers"

export const itemData = [
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      "162x122",
    ),
    title: "Fern",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1627308595229-7830a5c91f9f",
      "162x216",
    ),
    title: "Snacks",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      "162x108",
    ),
    title: "Mushrooms",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1529655683826-aba9b3e77383",
      "162x202",
    ),
    title: "Tower",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      "162x107",
    ),
    title: "Sea star",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      "162x108",
    ),
    title: "Honey",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      "162x108",
    ),
    title: "Basketball",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      "162x112",
    ),
    title: "Breakfast",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1627328715728-7bcc1b5db87d",
      "162x202",
    ),
    title: "Tree",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      "162x108",
    ),
    title: "Burger",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      "162x108",
    ),
    title: "Camera",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      "162x108",
    ),
    title: "Coffee",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1627000086207-76eabf23aa2e",
      "162x243",
    ),
    title: "Camping Car",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      "162x108",
    ),
    title: "Hats",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      "162x113",
    ),
    title: "Tomato basil",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1627328561499-a3584d4ee4f7",
      "162x91",
    ),
    title: "Mountain",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      "162x108",
    ),
    title: "Bike",
  },
]
