import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks"
import { useCallback } from "react"

const useFeedback = () => {
  const Swal = useSwalWrapper()

  const showDialog = useCallback(
    ({ titleText, text, icon, confirmText, cancelText } = {}) => {
      return Swal.fire({
        icon,
        titleText,
        text,
        confirmButtonText: confirmText || "Confirmar",
        confirmButtonColor: "primary",
        cancelButtonText: cancelText || "Cancelar",
        showCancelButton: true,
      })
    },
    [Swal],
  )

  const showToast = useCallback(
    ({ title, icon }) => {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer)
          toast.addEventListener("mouseleave", Swal.resumeTimer)
        },
      })

      return Toast.fire({
        icon,
        title,
      })
    },
    [Swal],
  )

  return { showDialog, showToast }
}

export default useFeedback
