import React from "react"
import Avatar from "@mui/material/Avatar"
import { authUser } from "./fake-db"
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import LogoutIcon from "@mui/icons-material/Logout"
import { useNavigate } from "react-router-dom"
import JumboDdPopover from "@jumbo/components/JumboDdPopover"
import useJumboTheme from "@jumbo/hooks/useJumboTheme"
import useJumboAuth from "@jumbo/hooks/useJumboAuth"

const AuthUserDropdown = () => {
  const navigate = useNavigate()
  const { theme } = useJumboTheme()
  const { setAuthToken } = useJumboAuth()

  const onLogout = () => {
    setAuthToken(null)
    navigate("/auth/login")
  }

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            sizes={"small"}
            sx={{ boxShadow: 25, cursor: "pointer" }}
          />
        }
      >
        <nav>
          <List disablePadding sx={{ pb: 1,  p: (theme) => theme.spacing(0.5)}}>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  )
}

export default AuthUserDropdown
