import Div from "@jumbo/shared/Div"
import { Edit } from "@mui/icons-material"
import { Avatar, Input, Skeleton, Stack } from "@mui/material"
import useFormCtx from "app/hooks/useFormCtx"
import { useRef, useState } from "react"

const EditableAvatar = ({
  value,
  onChange,
  initialSource,
  name,
  setValue,
  ...props
}) => {
  const { readOnly } = useFormCtx()
  const [fileUrl, setFileUrl] = useState(initialSource)
  const [wasLoaded, setWasLoaded] = useState(false)

  const inputRef = useRef(null)

  const changeHandler = (event) => {
    const file = event.target.files?.[0]

    if (onChange) {
      onChange(event)
    }

    if (!file) {
      return
    }

    setValue(file)

    const url = URL.createObjectURL(file)

    setFileUrl(url)
  }

  const editHandler = () => {
    if (!readOnly) {
      inputRef.current?.click()
    }
  }

  return (
    <Div sx={{ position: "relative" }}>
      <Avatar
        imgProps={{
          onLoad: setWasLoaded.bind(null, true),
        }}
        src={fileUrl}
        sx={{ width: 80, height: 80 }}
      />

      {!wasLoaded ? (
        <Div
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
        >
          <Skeleton variant="circular" width={80} height={80} />
        </Div>
      ) : null}

      <Stack
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          background: "#00000080",
          borderRadius: "100%",
          opacity: "0",
          transitionDuration: "0.3s",
          ":hover": {
            cursor: readOnly ? "default" : "pointer",
            opacity: readOnly ? 0 : 1,
          },
        }}
        alignItems="center"
        justifyContent="center"
        onClick={editHandler}
      >
        <Edit sx={{ color: "primary.contrastText" }} />
      </Stack>

      <Input
        inputRef={inputRef}
        type="file"
        name={name}
        value={value}
        sx={{ display: "none" }}
        onChange={changeHandler}
        {...props}
      />
    </Div>
  )
}

export default EditableAvatar
