import AppleIcon from "@mui/icons-material/Apple"
import MoneyOffIcon from "@mui/icons-material/MoneyOff"
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard"
import DiamondIcon from "@mui/icons-material/Diamond"
import InstagramIcon from "@mui/icons-material/Instagram"
import RestaurantIcon from "@mui/icons-material/Restaurant"
import AlbumIcon from "@mui/icons-material/Album"

export const recentActivities = [
  {
    icon: <AppleIcon />,
    title: "Expo 2.0 iOS app release",
    subTitle: "10 hours ago",
    color: "rgb(0, 95, 238)",
  },
  {
    icon: <MoneyOffIcon />,
    title: "Secure payment gateway implemented",
    subTitle: "4 days ago",
    color: "rgb(238, 114, 0)",
  },
  {
    icon: <CardGiftcardIcon />,
    title: "Reward points received",
    subTitle: "1 week ago",
    color: "green",
  },
  {
    icon: <DiamondIcon />,
    title: "A diamond badge is earned",
    subTitle: "3 weeks ago",
    color: "rgb(63, 81, 181)",
  },
  {
    icon: <InstagramIcon />,
    title: "An official instagram account is setup",
    subTitle: "2 months ago",
    color: "rgb(33, 150, 243)",
  },
  {
    icon: <RestaurantIcon />,
    title: "Dinner with client Jeff Brown",
    subTitle: "6 months ago",
    color: "rgb(238, 114, 0)",
  },
  {
    icon: <AlbumIcon />,
    title: "Blazing start of the timeline",
    subTitle: "1 year ago",
    color: "#6200EE",
  },
]
