import { MoreHorizOutlined } from "@mui/icons-material"
import { IconButton, Menu } from "@mui/material"
import { useState } from "react"

const BaseDriverMenu = ({ children }) => {
  const [anchorElement, setAnchorElement] = useState(null)
  const open = Boolean(anchorElement)

  const clickHandler = (event) => {
    setAnchorElement(event.currentTarget)
  }

  const closeHandler = () => {
    setAnchorElement(null)
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={clickHandler}
      >
        <MoreHorizOutlined />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={open}
        onClick={closeHandler}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {children}
      </Menu>
    </div>
  )
}

export default BaseDriverMenu
