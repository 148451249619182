import Swal from "sweetalert2"
import useStyles from "@jumbo/vendors/sweetalert2/style"
import withReactContent from "sweetalert2-react-content"
import { useMemo } from "react"

const useSwalWrapper = () => {
  const sweetAlertStyles = useStyles()

  const SwalWrapper = useMemo(
    () =>
      withReactContent(
        Swal.mixin({
          customClass: {
            container: `${sweetAlertStyles.container}`,
            popup: `${sweetAlertStyles.popup}`,
            title: `${sweetAlertStyles.title}`,
            closeButton: `${sweetAlertStyles.closeButton}`,
            image: `${sweetAlertStyles.image}`,
            htmlContainer: `${sweetAlertStyles.htmlContainer}`,
            confirmButton: `${sweetAlertStyles.confirmButton}`,
            cancelButton: `${sweetAlertStyles.cancelButton}`,
            footer: `${sweetAlertStyles.footer}`,
          },
          buttonsStyling: false,
        }),
      ),
    [sweetAlertStyles],
  )

  return SwalWrapper
}

export default useSwalWrapper
