import clientServices from "app/services/client-services"
import { useCallback, useState } from "react"
import useFeedback from "./useFeedback"

const useClients = () => {
  const [client, setClient] = useState()
  const [loadingState, setLoadingState] = useState({
    client: true,
    save: false,
    clients: true,
  })
  const [clients, setClients] = useState()
  const [sorting, setSorting] = useState()
  const [searchTerm, setSearchTerm] = useState(undefined)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)

  const { showToast } = useFeedback()

  const getClient = useCallback(async (id) => {
    setLoadingState((currentState) => ({ ...currentState, client: true }))

    try {
      const client = await clientServices.getClient(id)

      setClient(client)
    } catch {
    } finally {
      setLoadingState((currentState) => ({ ...currentState, client: false }))
    }
  }, [])

  const editClient = useCallback(
    async (client) => {
      setLoadingState((currentState) => ({ ...currentState, save: true }))

      try {
        await clientServices.editClient(client)

        showToast({ title: "Cliente atualizado com sucesso", icon: "success" })

        setLoadingState((currentState) => ({ ...currentState, save: false }))

        return true
      } catch (err) {
        setLoadingState((currentState) => ({ ...currentState, save: false }))
        const isBadRequest = err?.response.status !== 500
        showToast({
          title:
            isBadRequest && typeof err.response.data === "string"
              ? err.response.data
              : "Não foi possível atualizar o cliente no momento. Tente novamente depois!",
          icon: "error",
        })

        return false
      }
    },
    [showToast],
  )

  const getClients = useCallback(async () => {
    try {
      setLoadingState((currentState) => ({
        ...currentState,
        clients: true,
      }))

      const data = await clientServices.getClients({
        pageSize,
        page,
        searchTerm,
        sorting,
      })

      setClients(data.clients)
      setTotal(data.total)
    } catch {
      showToast({ title: "Não foi possível obter os clientes", icon: "error" })
    } finally {
      setLoadingState((currentState) => ({ ...currentState, clients: false }))
    }
  }, [pageSize, page, searchTerm, sorting, showToast])

  const toggleStatus = useCallback(
    async (client) => {
      const isActive = client.status === "active"

      try {
        setLoadingState((currentState) => ({ ...currentState, clients: true }))

        await clientServices.toggleClientStatus({
          id: client.id,
          status: client.status === "active" ? "inactive" : "active",
        })

        getClients()

        showToast({
          title: `Cliente ${isActive ? "inativado" : "ativado"} com sucesso`,
          icon: "success",
        })
      } catch {
        showToast({
          title:
            "Não foi possível alterar o cliente no momento. Tente novamente mais tarde",
          icon: "error",
        })
      } finally {
        setLoadingState((currentState) => ({ ...currentState, clients: true }))
      }
    },
    [getClients, showToast],
  )

  return {
    clients,
    getClients,
    pageSize,
    setPageSize,
    total,
    loadingState,
    page,
    setPage,
    sorting,
    setSorting,
    searchTerm,
    setSearchTerm,
    toggleStatus,
    client,
    getClient,
    editClient,
  }
}

export default useClients
