import JumboSearch from "@jumbo/components/JumboSearch"
import Div from "@jumbo/shared/Div"
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks"
import { Card, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { DataGrid, ptBR } from "@mui/x-data-grid"
import LoadingMessage from "app/components/shared/LoadingMessage"
import NoRowsMessage from "app/components/shared/NoRowsMessage"
import useDriverUpdate from "app/hooks/useDriverUpdate"
import useDrivers from "app/hooks/useDrivers"
import useFeedback from "app/hooks/useFeedback"
import { useDebouncedCallback } from "beautiful-react-hooks"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import {
  getApprovedColumns,
  getDisapprovedColumns,
  getPendingColumns,
} from "./utils"

const DriversListPage = () => {
  const {
    toggleStatus,
    setSorting,
    drivers,

    sorting,
    page,
    pageSize,
    setPage,
    setPageSize,
    total: totalDrivers,
    setSearchTerm,
    accountStatus,
    setAccountStatus,
    loadingDrivers,
  } = useDrivers()

  const { approveDriver, refuseDriver } = useDriverUpdate()

  const swal = useSwalWrapper()
  const navigate = useNavigate()

  const { showDialog } = useFeedback()

  const searchHandler = useDebouncedCallback(
    (term) => {
      setSearchTerm(term)
    },
    [],
    500,
  )

  const sortHandler = ([data]) => {
    setSorting(
      data
        ? {
            field: data.field,
            direction: data.sort,
          }
        : undefined,
    )
  }

  const changeAccountStatusHandler = (_, accountStatus) => {
    if (accountStatus !== null) {
      setAccountStatus(accountStatus)
    }
  }

  const columns = useMemo(() => {
    const editHandler = (driver) => {
      navigate(`/drivers/edit/${driver.driverId}`)
    }

    const viewHandler = (driver) => {
      navigate(`/drivers/view/${driver.driverId}`)
    }

    const toggleStatusHandler = async (driver) => {
      const isActive = driver.status === "active"

      const result = await showDialog({
        icon: "question",
        title: `${isActive ? "Desativar" : "Ativar"} motorista`,
        text: `Deseja realmente ${
          isActive ? "inativar" : "ativar"
        } esse motorista?`,
      })

      if (!result.isConfirmed) {
        return
      }

      toggleStatus(driver)
    }

    const approvalHandler = async (driver) => {
      const result = await showDialog({
        icon: "question",
        title: `Aprovar motorista`,
        text: `Deseja realmente aprovar a conta desse motorista?`,
      })

      if (!result.isConfirmed) {
        return
      }

      approveDriver(driver.driverId)
    }

    const disapprovalHandler = (driver) => {
      return swal.fire({
        icon: "question",
        title: `Reprovar motorista`,
        showLoaderOnConfirm: true,
        preConfirm: async (reason) => {
          try {
            await refuseDriver(driver.driverId, reason)
          } catch {}
        },
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        text: `Deseja realmente reprovar a conta desse motorista?`,
        input: "text",
        inputLabel: "Justificativa",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Você precisa justificar a reprovação do motorista."
          }
        },
      })
    }

    if (accountStatus === "aprovado") {
      return getApprovedColumns({
        onEdit: editHandler,
        onToggleStatus: toggleStatusHandler,
      })
    } else if (accountStatus === "pendente") {
      return getPendingColumns({
        onEdit: editHandler,
        onApprove: approvalHandler,
        onDisapprove: disapprovalHandler,
      })
    } else {
      return getDisapprovedColumns({
        onView: viewHandler,
        onApprove: approvalHandler,
      })
    }
  }, [
    accountStatus,
    navigate,
    showDialog,
    toggleStatus,
    swal,
    approveDriver,
    refuseDriver,
  ])

  return (
    <Div>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <ToggleButtonGroup
          sx={{ bgcolor: "white" }}
          exclusive
          value={accountStatus}
          onChange={changeAccountStatusHandler}
        >
          <ToggleButton color="success" value="aprovado">
            Aprovados
          </ToggleButton>
          <ToggleButton color="error" value="reprovado">
            Reprovados
          </ToggleButton>
          <ToggleButton color="warning" value="pendente">
            Pendentes
          </ToggleButton>
        </ToggleButtonGroup>
        <Card
          sx={{
            width: "fit-content",
            borderRadius: "3rem",
          }}
        >
          <JumboSearch
            sx={{ width: 300 }}
            placeholder="Buscar por motoristas"
            onChange={searchHandler}
          />
        </Card>
      </Div>

      {!drivers || loadingDrivers ? (
        <LoadingMessage message="Carregando motoristas" />
      ) : (
        <Card sx={{ p: 2 }}>
          {drivers?.length ? (
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              disableColumnMenu
              density="comfortable"
              disableRowSelectionOnClick
              rows={drivers}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: page - 1, pageSize },
                },
                sorting: sorting
                  ? {
                      sortModel: [
                        { field: sorting.field, sort: sorting.direction },
                      ],
                    }
                  : undefined,
              }}
              pageSizeOptions={[10, 25, 50]}
              onPaginationModelChange={(model) => {
                setPage(model.page + 1)
                setPageSize(model.pageSize)
              }}
              rowCount={totalDrivers}
              filterMode="server"
              sortingMode="server"
              paginationMode="server"
              onSortModelChange={sortHandler}
            />
          ) : null}
          {!drivers?.length && <NoRowsMessage />}
        </Card>
      )}
    </Div>
  )
}

export default DriversListPage
