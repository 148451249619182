import * as React from "react"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import JumboDemoCard from "@jumbo/components/JumboDemoCard"
import code from "../Menus/demo-code/context-menu.txt"

const ContextMenu = () => {
  const [contextMenu, setContextMenu] = React.useState(null)

  const handleContextMenu = (event) => {
    event.preventDefault()
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    )
  }

  const handleClose = () => {
    setContextMenu(null)
  }

  return (
    <JumboDemoCard
      title={"Context Menu"}
      subheader={"Here is an example of a context menu. (Right click to open.)"}
      demoCode={code}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <div onContextMenu={handleContextMenu} style={{ cursor: "context-menu" }}>
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ipsum
          purus, bibendum sit amet vulputate eget, porta semper ligula. Donec
          bibendum vulputate erat, ac fringilla mi finibus nec. Donec ac dolor
          sed dolor porttitor blandit vel vel purus. Fusce vel malesuada ligula.
          Nam quis vehicula ante, eu finibus est. Proin ullamcorper fermentum
          orci, quis finibus massa. Nunc lobortis, massa ut rutrum ultrices,
          metus metus finibus ex, sit amet facilisis neque enim sed neque.
          Quisque accumsan metus vel maximus consequat. Suspendisse lacinia
          tellus a libero volutpat maximus.
        </Typography>
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleClose}>Copy</MenuItem>
          <MenuItem onClick={handleClose}>Print</MenuItem>
          <MenuItem onClick={handleClose}>Highlight</MenuItem>
          <MenuItem onClick={handleClose}>Email</MenuItem>
        </Menu>
      </div>
    </JumboDemoCard>
  )
}
export default ContextMenu
