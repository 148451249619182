import {
  createBiDirectionalMap,
  createServerFile,
  fileAsBase64,
  ifFile,
} from "app/utils/appHelpers"
import { formatCPF, formatPhone, getOnlyNumbers } from "app/utils/format"
import jwtAuthAxios from "./auth/jwtAuth"

const driverServices = {}

const driverStatusMapper = createBiDirectionalMap({
  approved: "aprovado",
  pending: "pendente",
  refused: "reprovado",
})

driverServices.changeApproval = ({ id, status, reason }) => {
  return jwtAuthAxios.put(`/admin/motoristas/${id}/status-conta`, {
    status: driverStatusMapper[status],
    justificativa: reason,
  })
}

driverServices.getDriver = async (id) => {
  const {
    data: { motorista: data },
  } = await jwtAuthAxios.get(`/motoristas/${id}`)

  return decodeDriver(data)
}

driverServices.editDriver = async (driver) => {
  const encodedData = await encodeDriver(driver)

  const { data } = await jwtAuthAxios.put(
    `/admin/motoristas/${driver.id}`,
    encodedData,
  )

  return data
}

driverServices.getDrivers = async ({
  pageSize,
  page,
  searchTerm,
  sorting,
  accountStatus,
}) => {
  const params = {
    perPage: pageSize,
    page,
  }

  if (
    accountStatus &&
    ["aprovado", "reprovado", "pendente"].includes(accountStatus)
  ) {
    params.status_conta = accountStatus
  }

  if (searchTerm) {
    params.field = searchTerm.replace(/[^0-9a-zA-Z]/g, "")
  }

  if (sorting) {
    params.orderBy =
      {
        name: "nome",
        personalId: "cpf",
        phone: "telefone",
      }[sorting.field] || sorting.field
    params.orderDirection = sorting.direction
  }

  const { data } = await jwtAuthAxios.get("/motoristas", { params })

  return {
    drivers: data.usuarios.map((driver) => ({
      id: driver.usuario.id,
      driverId: driver.veiculo.motoristaId,
      name: driver.usuario.nome,
      personalId: driver.usuario.cpf,
      phone: driver.usuario.telefone,
      email: driver.usuario.email,
      status: driver.usuario.status === "ativo" ? "active" : "inactive",
      ...(driver.statusConta.status === "reprovado" && {
        reason: driver.statusConta.justificativa,
      }),
    })),
    total: data.paginationProps.total,
  }
}

driverServices.toggleDriverStatus = async ({ id, status }) => {
  const parsedStatus = status === "active" ? "ativo" : "inativo"

  const { data } = await jwtAuthAxios.post(`/admin/usuarios/${id}`, {
    status: parsedStatus,
  })

  return data
}

const decodeDriver = async (driver) => {
  const {
    id,
    cnh,
    arquivoCnhName,
    arquivoCnh,
    arquivoCnhSize,
    usuario: user,
    statusConta: driverStatus,
    contaBanco: financialData,
    veiculo: vehicle,
    endereco: encodedAddress,
    cep: postalCode,
  } = driver

  const parsedFinancialData = {
    agency: "",
    account: "",
    pixKey: "",
    pixKeyType: "",
  }

  if (financialData) {
    const account = getOnlyNumbers(financialData.numeroConta)
    const formattedAccount = `${account.slice(0, account.length - 1)}-${
      account[account.length - 1]
    }`
    const agency = getOnlyNumbers(financialData.agencia)
    const formattedAgency = `${agency.slice(0, agency.length - 1)}-${
      agency[agency.length - 1]
    }`

    parsedFinancialData.bank = {
      id: financialData.ispbBanco,
      label: financialData.nomeBanco,
    }
    parsedFinancialData.agency = formattedAgency
    parsedFinancialData.account = formattedAccount
    parsedFinancialData.pixKey = financialData.pix
    parsedFinancialData.pixKeyType = financialData.tipoPix
  }

  const [address, number, city] = encodedAddress.split("///") || []
  const userAddressHasNumber = number && number !== "Sem número"

  const avatar = await new Promise((resolve) => {
    if (!user.foto) {
      return resolve()
    }

    jwtAuthAxios.get(decodeURIComponent(user.foto)).then((result) => {
      resolve(result.data.content || result.data)
    })
  })

  return {
    id: id,
    name: user.nome,
    email: user.email,
    avatar,
    cnh: cnh,
    phone: formatPhone(user.telefone),
    personalId: user.cpf ? formatCPF(user.cpf) : "",
    driverStatus: driverStatusMapper[driverStatus.status],
    status: user.status === "ativo" ? "active" : "inactive",
    ...parsedFinancialData,
    licensePlate: vehicle.placa,
    vehicleType: vehicleTypeMapper[vehicle.tipoVeiculo],
    model: vehicle.modelo,
    cnhImage: createServerFile(
      decodeURIComponent(arquivoCnh),
      removeIdFromDocumentName(arquivoCnhName),
      arquivoCnhSize,
    ),
    crlvImage: createServerFile(
      decodeURIComponent(vehicle.arquivoCrlv),
      removeIdFromDocumentName(vehicle.arquivoCrlvName),
      vehicle.arquivoClrvSize,
    ),
    address,
    number: userAddressHasNumber ? number : "",
    addressWithoutNumber: !userAddressHasNumber,
    city,
    postalCode,
  }
}

const vehicleTypeMapper = createBiDirectionalMap({
  bike: "moto",
  largeTruck: "caminhao",
  smallTruck: "utilitario_grande",
  car: "carro",
  pickup: "utilitario_pequeno",
})

const encodeDriver = async (driver) => {
  const bankData = {}

  if (driver.bank?.id) {
    bankData.ispbBanco = driver.bank?.id
    bankData.numeroConta = getOnlyNumbers(driver.account)
    bankData.agencia = getOnlyNumbers(driver.agency)
    bankData.pix = driver.pixKey
    bankData.tipo_pix = driver.pixKeyType
  }

  const parsedData = {
    ...bankData,
    cnh: getOnlyNumbers(driver.cnh),
    cpf: getOnlyNumbers(driver.personalId),
    telefone: getOnlyNumbers(driver.phone),
    nome: driver.name,
    email: driver.email,
    placa: driver.licensePlate,
    tipo: vehicleTypeMapper[driver.vehicleType],
    modelo: driver.model,
    status: driver.status === "active" ? "ativo" : "inativo",
    ...ifFile("foto", driver.avatar),
    ...ifFile("arquivo_cnh", driver.cnhImage),
    ...ifFile("arquivo_crlv", driver.crlvImage),
    endereco: [driver.address, driver.number || "", driver.city]
      .map((item) => item?.trim() || "")
      .join("///"),
    cep: getOnlyNumbers(driver.postalCode),
  }

  await Promise.all([
    new Promise(async (resolve) => {
      if (parsedData.foto) {
        parsedData.foto = await fileAsBase64(parsedData.foto)
      }

      resolve()
    }),
    new Promise(async (resolve) => {
      if (parsedData.arquivo_cnh) {
        parsedData.arquivo_cnh = await fileAsBase64(parsedData.arquivo_cnh)
      }

      resolve()
    }),
    new Promise(async (resolve) => {
      if (parsedData.arquivo_crlv) {
        parsedData.arquivo_crlv = await fileAsBase64(parsedData.arquivo_crlv)
      }

      resolve()
    }),
  ])

  return JSON.stringify(parsedData)
}

const removeIdFromDocumentName = (fileName) => {
  const nameParts = fileName?.split("-") ?? []
  if (nameParts.length > 2) {
    nameParts.pop()
  }

  return nameParts.join("-")
}

export default driverServices
