import { Autocomplete, TextField } from "@mui/material"
import useFeedback from "app/hooks/useFeedback"
import useFormCtx from "app/hooks/useFormCtx"
import bankServices from "app/services/bank-services"
import { useDebouncedCallback } from "beautiful-react-hooks"
import { useState } from "react"
import { useQuery } from "react-query"

const BankSelector = ({ value, onChange, error, required, onBlur }) => {
  const { readOnly } = useFormCtx()
  const [searchInput, setSearchInput] = useState("")
  const { showToast } = useFeedback()

  const { data: bankList, isLoading: loadingBanks } = useQuery(
    ["banks", searchInput],
    () => bankServices.getBanks(searchInput),
    {
      select: (data) =>
        data.map((bank) => ({
          label: bank.ShortName,
          id: bank.ISPB,
        })),
      onError: () => {
        showToast({
          title: "Não foi possível obter lista de bancos",
          icon: "error",
        })
      },
    },
  )

  const debouncedSearchHandler = useDebouncedCallback(setSearchInput, [], 200)
  return (
    <Autocomplete
      readOnly={readOnly}
      onBlur={onBlur}
      loading={loadingBanks}
      value={value ?? null}
      onChange={(_, val) => onChange(val)}
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      getOptionLabel={(val) => val.label}
      onInputChange={(_, val) => debouncedSearchHandler(val)}
      options={bankList ?? []}
      filterOptions={(val) => val}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label="Banco"
          error={Boolean(error)}
          helperText={error}
        />
      )}
    />
  )
}

export default BankSelector
