const { useRef } = require("react")

const useDebounce = (callback, time) => {
  const timeoutRef = useRef(null)

  const handler = (...data) => {
    clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(callback.bind(null, ...data), time)
  }

  return handler 
}

export default useDebounce