import JumboSearch from "@jumbo/components/JumboSearch"
import Div from "@jumbo/shared/Div"
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks"
import { Card } from "@mui/material"
import { DataGrid, ptBR } from "@mui/x-data-grid"
import HistoryFilters from "app/components/HistoryList/HistoryFilters"
import LoadingMessage from "app/components/shared/LoadingMessage"
import NoRowsMessage from "app/components/shared/NoRowsMessage"
import useDeliveryHistory from "app/hooks/useDeliveryHistory"
import useMercadoPago from "app/hooks/useMercadoPago"
import { useNavigate } from "react-router-dom"
import { getColumns } from "./utils"

const HistoryListPage = () => {
  const swal = useSwalWrapper()
  const { refundTransaction } = useMercadoPago()
  const {
    deliveryHistory,
    isLoading,
    page,
    setSearchTerm,
    setSorting,
    pageSize,
    sorting,
    total,
    setPage,
    setPageSize,
    filters,
    setFilters,
  } = useDeliveryHistory()

  const navigate = useNavigate()

  const sortHandler = ([data]) => {
    setSorting(
      data
        ? {
            field: data.field,
            direction: data.sort,
          }
        : undefined,
    )
  }

  const refundHandler = async (entry) => {
    swal.fire({
      icon: "question",
      title: `Reembolsar pagamento`,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await refundTransaction(entry.mpPaymentId)
        } catch {}
      },
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      text: `Deseja realmente reembolsar esse pagamento?`,
      showCancelButton: true,
    })
  }

  const viewHandler = async (entry) => {
    navigate(`/history/view/${entry.id}`)
  }

  return (
    <Div
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Card sx={{ width: "fit-content", ml: "auto", borderRadius: "3rem" }}>
        <JumboSearch
          timeout={500}
          sx={{ width: 300 }}
          placeholder="Buscar por cliente ou motorista"
          onChange={setSearchTerm}
        />
      </Card>
      <HistoryFilters onChangeFilters={setFilters} values={filters} />
      {!deliveryHistory && isLoading ? (
        <LoadingMessage message="Carregando histórico" />
      ) : (
        <Card sx={{ p: 2 }}>
          {deliveryHistory?.length ? (
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              disableColumnMenu
              density="comfortable"
              disableRowSelectionOnClick
              rows={deliveryHistory}
              columns={getColumns({
                onView: viewHandler,
                onRefund: refundHandler,
              })}
              initialState={{
                pagination: {
                  paginationModel: { page: page - 1, pageSize },
                },
                sorting: sorting
                  ? {
                      sortModel: [
                        { field: sorting.field, sort: sorting.direction },
                      ],
                    }
                  : undefined,
              }}
              pageSizeOptions={[10, 25, 50]}
              onPaginationModelChange={(model) => {
                setPage(model.page + 1)
                setPageSize(model.pageSize)
              }}
              loading={isLoading}
              rowCount={total}
              filterMode="server"
              sortingMode="server"
              paginationMode="server"
              onSortModelChange={sortHandler}
            />
          ) : null}
          {!deliveryHistory?.length && <NoRowsMessage />}
        </Card>
      )}
    </Div>
  )
}

export default HistoryListPage
