import React, { useState } from "react"
import { Alert, Card, CardContent, Link, Typography } from "@mui/material"
import { alpha } from "@mui/material/styles"
import Div from "@jumbo/shared/Div"
import * as yup from "yup"
import { Form, Formik } from "formik"
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField"
import { LoadingButton } from "@mui/lab"
import authServices from "app/services/auth-services"
import { useNavigate } from "react-router-dom"
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks"

const validationSchema = yup.object({
  email: yup
    .string("O e-mail é obrigatório")
    .email("E-mail inválido")
    .required("O e-mail é obrigatório"),
})

const ForgotPassword = () => {
  const Swal = useSwalWrapper()

  const [error, setError] = useState("")
  const navigate = useNavigate()

  const onSendEmail = async (email) => {
    try {
      await authServices.forgotPassword(email)

      Swal.fire({
        title: "E-mail enviado!",
        text: "E-mail de redefinição de senha enviado com sucesso",
        icon: "success",
        confirmButtonText: "Ok",
      }).then(() => {
        navigate("/auth/login")
      })
    } catch {
      setError("E-mail não cadastrado")
    }
  }

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
        <Div sx={{ position: "relative", height: "200px" }}>
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              backgroundColor: alpha("#0267a0", 0.65),
              p: (theme) => theme.spacing(3),
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: "common.white",
                fontSize: "1.5rem",
                mb: 0,
              }}
            >
              Recuperar senha
            </Typography>
            <Typography
              variant={"body1"}
              mb={1}
              sx={{ maxWidth: 270, color: "common.white" }}
            >
              Insira o seu e-mail a seguir para receber um e-mail de redefinição
              de senha
            </Typography>
          </Div>
        </Div>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
              setError("")
              setSubmitting(true)
              await onSendEmail(data.email, data.password)
              setSubmitting(false)
            }}
          >
            {({ isSubmitting, setTouched, dirty, isValid }) => (
              <Form
                style={{ textAlign: "left" }}
                noValidate
                autoComplete="off"
                onChange={setError.bind(null, "")}
              >
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField
                    fullWidth
                    name="email"
                    label="E-mail"
                    InputProps={{ id: "email" }}
                    InputLabelProps={{ htmlFor: "email" }}
                  />
                </Div>

                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 1 }}
                  loading={isSubmitting}
                  disabled={!dirty || !isValid}
                  onClick={setTouched.bind(null, { email: true })}
                  data-testid="submit-button"
                >
                  Enviar e-mail
                </LoadingButton>
              </Form>
            )}
          </Formik>

          <Link href="/auth/login" underline="none">
            <LoadingButton fullWidth type="button" variant="text" size="large">
              Voltar
            </LoadingButton>
          </Link>

          {error ? (
            <Div sx={{ mt: 3 }}>
              <Alert severity="error" data-testid="error-message">
                {error}
              </Alert>
            </Div>
          ) : null}
        </CardContent>
      </Card>
    </Div>
  )
}

export default ForgotPassword
