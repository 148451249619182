import driverServices from "app/services/driver-services"
import { useQuery } from "react-query"
import useFeedback from "./useFeedback"

const useDriver = (id) => {
  const { showToast } = useFeedback()
  const { isLoading, data: driver } = useQuery(
    ["drivers", id],
    () => driverServices.getDriver(id),
    {
      onError: () => {
        showToast({
          title:
            "Não foi possível obter dados do motorista, tente novamente mais tarde",
          icon: "error",
        })
      },
    },
  )

  return {
    isLoading,
    driver,
  }
}

export default useDriver
