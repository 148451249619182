import * as React from "react"
import MenuList from "@mui/material/MenuList"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Typography from "@mui/material/Typography"
import DraftsIcon from "@mui/icons-material/Drafts"
import SendIcon from "@mui/icons-material/Send"
import PriorityHighIcon from "@mui/icons-material/PriorityHigh"
import JumboDemoCard from "@jumbo/components/JumboDemoCard"
import code from "../Menus/demo-code/typography-menu.txt"
import Paper from "@mui/material/Paper"

const TypographyMenu = () => {
  return (
    <JumboDemoCard title={"Limitations"} demoCode={code}>
      <Paper sx={{ width: 230, maxWidth: "100%" }}>
        <MenuList>
          <MenuItem>
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">A short message</Typography>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <PriorityHighIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">
              A very long text that overflows
            </Typography>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <DraftsIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              A very long text that overflows
            </Typography>
          </MenuItem>
        </MenuList>
      </Paper>
    </JumboDemoCard>
  )
}
export default TypographyMenu
