import { Card, CircularProgress, Typography } from '@mui/material'

const LoadingMessage = ({ message = "Loading" }) => {

  return <Card
    sx={{
      p: 3,
      py: 6,
      display: "flex",
      alignItems: "center",
      gap: 3,
      justifyContent: "center",
    }}
  >
    <CircularProgress />
    <Typography variant="h3" sx={{ color: "primary.main", m: 0 }}>
      {message}
    </Typography>
  </Card>
}

export default LoadingMessage