import { USE_IMAGE_PLACEHOLDERS } from "./constants/paths"

export const getAssetPath = (url, size) => {
  if (USE_IMAGE_PLACEHOLDERS) {
    return `https://via.placeholder.com/${size}.png`
  }

  return url
}

export const transformBodyIntoFormData = (body) => {
  const formData = new FormData()
  Object.entries(body).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      if (val.length === 0) return
      for (const fieldElement of val) {
        if (val) {
          if (fieldElement instanceof File) {
            formData.append(key, encodeFileName(fieldElement))
          } else formData.append(key, fieldElement)
        }
      }
    } else {
      formData.append(key, val)
    }
  })
  return formData
}

export const encodeFileName = (file) => {
  return new File([file], encodeURI(file.name), { type: file.type })
}

export const createBiDirectionalMap = (obj) => {
  return Object.entries(obj).reduce(
    (prev, [key, val]) => ({
      ...prev,
      [key]: val,
      [val]: key,
    }),
    {},
  )
}

export const createServerFile = (imageReqURL, imageName, imageSize) => {
  return {
    name: imageName,
    size: imageSize,
    url: imageReqURL,
  }
}

export const ifFile = (key, value) => {
  if (value instanceof File) {
    return { [key]: value }
  }
  return {}
}

export const fileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsDataURL(file)
  })
}
