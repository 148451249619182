import Div from "@jumbo/shared/Div"
import {
  CreditCardOff,
  MoreHorizOutlined,
  Visibility,
} from "@mui/icons-material"
import { IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { useState } from "react"

const EntryMenu = ({ onView, onRefund }) => {
  const [anchorElement, setAnchorElement] = useState(null)
  const open = Boolean(anchorElement)

  const clickHandler = (event) => {
    setAnchorElement(event.currentTarget)
  }

  const closeHandler = () => {
    setAnchorElement(null)
  }

  const optionClickHandler = (key) => {
    closeHandler()

    if (key === "view") {
      onView()
    } else {
      onRefund()
    }
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={clickHandler}
      >
        <MoreHorizOutlined />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={closeHandler}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={optionClickHandler.bind(null, "view")}>
          <Div sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Visibility sx={{ fontSize: "1rem" }} />
            <Typography>Visualizar</Typography>
          </Div>
        </MenuItem>
        {onRefund && (
          <MenuItem onClick={optionClickHandler.bind(null, "refund")}>
            <Div sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <CreditCardOff sx={{ fontSize: "1rem" }} />
              <Typography>Reembolsar</Typography>
            </Div>
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default EntryMenu
