import { format } from "date-fns"

/**
 * @param {string} cpf
 * @returns {string}
 */
export const formatCPF = (cpf) => {
  return [
    cpf?.slice(0, 3),
    ".",
    cpf?.slice(3, 6),
    ".",
    cpf?.slice(6, 9),
    "-",
    cpf?.slice(9) || "00",
  ].join("")
}

/**
 * @param {string} phone
 * @returns {string}
 */
export const formatPhone = (phone) => {
  return [
    "(",
    phone?.slice(0, 2),
    ") ",
    phone?.length === 11 ? phone?.slice(2, 7) : phone?.slice(2, 6),
    "-",
    phone?.length === 11 ? phone?.slice(7) : phone?.slice(6),
  ].join("")
}

/**
 * @param {string} value
 * @returns {string}
 */
export const getOnlyNumbers = (value) => {
  return value ? value.replace(/\D/g, "") : ""
}

/**
 * @param {string | number} value
 * @returns {string}
 */
export const formatToCurrency = (value) => {
  let parsedValue = parseFloat(value)
  if (isNaN(parsedValue)) {
    parsedValue = 0
  }
  return parsedValue.toLocaleString("pt-BR", {
    currency: "BRL",
    style: "currency",
  })
}

/**
 * Formats date to the dd/mm/yyyy hh:mm format
 * @param {string | Date} value
 * @returns {string} - formatted date
 */
export const formatDateHour = (value) => {
  let date = new Date(value)
  const onlyDateRegex = new RegExp(/^\d\d(\d\d)?-\d\d-\d\d(\d\d)?$/)
  // Is in the xx/xx/xxxx format
  if (onlyDateRegex.test(value)) {
    // Add our local timezone to avoid ISO on date-fns format
    date = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000)
  }

  return format(date, "dd/MM/yyyy HH:mm")
}
