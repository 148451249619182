import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import { useState } from "react"
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField"
import { InputAdornment, IconButton } from "@mui/material"
import { useField } from "formik"

const PasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const meta = useField(props)[1]

  const togglePasswordVisibility = () => {
    setShowPassword((state) => !state)
  }

  const iconMouseDownHandler = (event) => {
    event.preventDefault()
  }

  const Icon = showPassword ? VisibilityOffOutlined : VisibilityOutlined

  const fieldHasError = meta.error && meta.touched

  return (
    <JumboTextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility.bind(null, true)}
              onMouseDown={iconMouseDownHandler}
              edge="end"
            >
              <Icon color={fieldHasError ? "error" : undefined} />
            </IconButton>
          </InputAdornment>
        ),
        ...(props.InputProps || {})
      }}
    />
  )
}

export default PasswordField
