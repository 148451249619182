import { ASSET_AVATARS } from "../../utils/constants/paths"
import { getAssetPath } from "../../utils/appHelpers"

export const labels = [
  { id: 1, name: "Banking", slug: "banking", color: "#E0CDFF" },
  { id: 2, name: "Company", slug: "company", color: "#00C4B4" },
  { id: 3, name: "Payments", slug: "payments", color: "#0F9AF7" },
]

export const contacts = [
  {
    id: 1451,
    name: "Stella Johnson",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, "40x40"),
    email: "stella@example.com",
    phone: "(215)-659-7529",
    designation: "CEO",
    starred: false,
    frequent: true,
    company: "TCS Ltd.",
    labels: [2],
    folder: "contacts",
  },
  {
    id: 1452,
    name: "Garry Sobars",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
    email: "garry@example.com",
    phone: "(215)-659-7529",
    designation: "CFO",
    starred: false,
    frequent: false,
    company: "InfoSys Inc.",
    labels: [2, 1],
    folder: "contacts",
  },
  {
    id: 1453,
    name: "Alex Dolgove",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar5.jpg`, "40x40"),
    email: "alex@example.com",
    phone: "(215)-748-7855",
    designation: "Designer",
    starred: false,
    frequent: false,
    company: "Accenture",
    labels: [3],
    folder: "contacts",
  },
  {
    id: 1454,
    name: "Domnic Brown",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar6.jpg`, "40x40"),
    email: "domnic@example.com",
    phone: "(215)-659-7529",
    designation: "PHP Developer",
    starred: false,
    frequent: true,
    company: "Pizza Hut",
    labels: [3],
    folder: "contacts",
  },
  {
    id: 1455,
    name: "Kadir M",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, "40x40"),
    email: "kadir@example.com",
    phone: "(215)-659-8965",
    designation: "HR Manager",
    starred: true,
    frequent: false,
    company: "Dominos Ltd.",
    labels: [2, 3],
    folder: "contacts",
  },
  {
    id: 1456,
    name: "John Smith",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar8.jpg`, "40x40"),
    email: "john@example.com",
    phone: "(215)-876-5434",
    designation: "Marketing Head",
    starred: true,
    frequent: false,
    company: "Subway",
    labels: [3, 1],
    folder: "contacts",
  },
  {
    id: 1457,
    name: "Domnic Harris",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar9.jpg`, "40x40"),
    email: "domnic@example.com",
    phone: "(215)-659-7529",
    designation: "BDO",
    starred: true,
    frequent: true,
    company: "Honda Inc.",
    labels: [],
    folder: "contacts",
  },
  {
    id: 1458,
    name: "Jimmy Jo",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar10.jpg`, "40x40"),
    email: "jimmy@example.com",
    phone: "(215)-456-2346",
    designation: "CCO",
    starred: false,
    frequent: false,
    company: "TVS Corp.",
    labels: [3],
    folder: "contacts",
  },
  {
    id: 1459,
    name: "Jimmy Jon",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar11.jpg`, "40x40"),
    email: "jimmy@example.com",
    phone: "(215)-278-4357",
    designation: "Developer",
    starred: true,
    frequent: false,
    company: "Hero Honda",
    labels: [2, 1],
    folder: "contacts",
  },
  {
    id: 1460,
    name: "Jeson Born",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar12.jpg`, "40x40"),
    email: "jeson@example.com",
    phone: "(215)-286-0788",
    designation: "UI-UX Designer",
    starred: false,
    frequent: false,
    company: "Hyundai Ltd.",
    labels: [1, 2, 3],
    folder: "contacts",
  },
  {
    id: 1461,
    name: "Steve Smith",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar13.jpg`, "40x40"),
    email: "steve@example.com",
    phone: "(215)-586-4676",
    designation: "CEO",
    starred: false,
    frequent: true,
    company: "Maruti Corp",
    labels: [1],
    folder: "contacts",
  },
  {
    id: 1462,
    name: "Stella Johnson",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar14.jpg`, "40x40"),
    email: "stella@example.com",
    phone: "(215)-659-7529",
    designation: "CEO",
    starred: false,
    frequent: true,
    company: "Chevrolet",
    labels: [1, 2, 3],
    folder: "contacts",
  },
  {
    id: 1463,
    name: "Garry Sobars",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, "40x40"),
    email: "garry@example.com",
    phone: "(215)-745-2345",
    designation: "CFO",
    starred: true,
    frequent: true,
    company: "Morgan Garage",
    labels: [2],
    folder: "contacts",
  },
  {
    id: 1464,
    name: "Alex Dolgove",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar4.jpg`, "40x40"),
    email: "alex@example.com",
    phone: "(215)-748-3265",
    designation: "Designer",
    starred: false,
    frequent: false,
    company: "Tata Pvt. Ltd.",
    labels: [],
    folder: "contacts",
  },
  {
    id: 1465,
    name: "Domnic Brown",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar5.jpg`, "40x40"),
    email: "domnic@example.com",
    phone: "(215)-756-3465",
    designation: "PHP Developer",
    starred: false,
    frequent: true,
    Company: "Levis Ltd.",
    labels: [2],
    folder: "contacts",
  },
  {
    id: 1466,
    name: "Kadir M",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar6.jpg`, "40x40"),
    email: "kadir@example.com",
    phone: "(215)-659-3246",
    designation: "HR Manager",
    starred: true,
    frequent: false,
    company: "John Players",
    labels: [3, 2],
    folder: "contacts",
  },
  {
    id: 1467,
    name: "John Smith",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, "40x40"),
    email: "john@example.com",
    phone: "(215)-876-3246",
    designation: "Marketing Head",
    starred: false,
    frequent: false,
    company: "Jaguar Ltd.",
    labels: [],
    folder: "contacts",
  },
  {
    id: 1468,
    name: "Domnic Harris",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar8.jpg`, "40x40"),
    email: "domnic@example.com",
    phone: "(215)-785-0967",
    designation: "BDO",
    starred: true,
    frequent: false,
    company: "Reliance Energy",
    labels: [],
    folder: "contacts",
  },
  {
    id: 1469,
    name: "Jimmy Jo",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar9.jpg`, "40x40"),
    email: "jimmy@example.com",
    phone: "(215)-456-0677",
    designation: "CCO",
    starred: false,
    frequent: true,
    company: "Flipkart",
    labels: [],
    folder: "contacts",
  },
  {
    id: 1470,
    name: "Jimmy Jon",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar10.jpg`, "40x40"),
    email: "jimmy@example.com",
    phone: "(215)-278-5475",
    designation: "Developer",
    starred: true,
    frequent: false,
    company: "Snapdeal",
    labels: [2, 3, 1],
    folder: "contacts",
  },
  {
    id: 1471,
    name: "Jeson Born",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar11.jpg`, "40x40"),
    email: "jeson@example.com",
    phone: "(215)-286-0456",
    designation: "UI-UX Designer",
    starred: false,
    frequent: false,
    company: "Amazon Services",
    labels: [],
    folder: "contacts",
  },
  {
    id: 1472,
    name: "Steve Smith",
    profile_pic: getAssetPath(`${ASSET_AVATARS}/avatar12.jpg`, "40x40"),
    email: "steve@example.com",
    phone: "(215)-586-2355",
    designation: "CEO",
    starred: true,
    frequent: false,
    company: "Myntra",
    labels: [2, 1],
    folder: "contacts",
  },
]
