export const countryList = [
  {
    name: "United State",
    flagCode: "US",
    visitors: "12,455",
    badgeColor: "#D72934",
  },
  { name: "France", flagCode: "FR", visitors: "445", badgeColor: "#3F51B5" },
  {
    name: "Germany",
    flagCode: "GM",
    visitors: "36,855",
    badgeColor: "#FFA005",
  },
  { name: "Spain", flagCode: "ES", visitors: "9,877", badgeColor: "#5D9405" },
]

export const countriesMarkers = [
  { latLng: [51.1657, 10.4515], name: "Germany" },
  { latLng: [40.73061, -73.935242], name: "USA" },
  { latLng: [40.416775, -3.70379], name: "Spain" },
  { latLng: [46.2276, 2.2137], name: "France" },
]
