import Div from "@jumbo/shared/Div"
import { ArrowUpward, EditOutlined } from "@mui/icons-material"
import { MenuItem, Typography } from "@mui/material"
import BaseDriverMenu from "./BaseDriverMenu"

const ApprovedDriverMenu = ({ onEdit, onToggleStatus, driverIsActive }) => {
  return (
    <BaseDriverMenu>
      <MenuItem onClick={onEdit}>
        <Div sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <EditOutlined sx={{ fontSize: "1rem" }} />
          <Typography>Editar</Typography>
        </Div>
      </MenuItem>
      <MenuItem onClick={onToggleStatus}>
        <Div sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <ArrowUpward
            sx={{
              fontSize: "1rem",
              transform: driverIsActive ? "rotate(180deg)" : "rotate(0)",
            }}
          />
          <Typography>{driverIsActive ? "Desativar" : "Ativar"}</Typography>
        </Div>
      </MenuItem>
    </BaseDriverMenu>
  )
}

export default ApprovedDriverMenu
