import { Chip } from "@mui/material"

/**
 * @param {object} props
 * @param {boolean} props.isActive
 * @returns
 */
const StatusChip = ({ isActive }) => {
  return (
    <Chip
      label={isActive ? "Ativo" : "Inativo"}
      variant="filled"
      size="small"
      color={isActive ? "success" : "error"}
    />
  )
}

export default StatusChip
