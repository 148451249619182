import jwtAuthAxios from "./auth/jwtAuth"
import decodeJWT from "jwt-decode"

const authServices = {}

authServices.getCurrentUser = async () => {
  return Promise.resolve({})
}

authServices.signIn = async ({ email, password }) => {
  const { data } = await jwtAuthAxios.post("/login", {
    email: email,
    senha: password,
  })

  return data
}

authServices.forgotPassword = async (email) => {
  const { data } = await jwtAuthAxios.get("/recover-password", {
    params: { email },
  })

  return data
}

authServices.resetPassword = async ({
  password,
  passwordConfirmation,
  token,
}) => {
  const { data } = await jwtAuthAxios.put("/reset-password", {
    token: decodeJWT(token)?.token,
    novaSenha: password,
    confirmacaoNovaSenha: passwordConfirmation,
  })

  return data
}

export default authServices
