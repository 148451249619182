import React from "react"
import Div from "@jumbo/shared/Div"
import Link from "@mui/material/Link"
import { ASSET_IMAGES } from "../../utils/constants/paths"

const Logo = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: "inline-flex", ...sx }}>
      <Link href={"/"}>
        {!mini ? (
          <img
            src={
              mode === "light"
                ? `${ASSET_IMAGES}/logo.png`
                : `${ASSET_IMAGES}/logo-white.png`
            }
            alt="FretApp"
            style={{ width: 56 }}
          />
        ) : (
          <img
            src={
              mode === "light"
                ? `${ASSET_IMAGES}/logo-short.png`
                : `${ASSET_IMAGES}/logo-short-white.png`
            }
            alt="FretApp"
          />
        )}
      </Link>
    </Div>
  )
}

Logo.defaultProps = {
  mode: "light",
}

export default Logo
