import * as React from "react"
import Grid from "@mui/material/Grid"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"
import Paper from "@mui/material/Paper"
import JumboDemoCard from "@jumbo/components/JumboDemoCard"
import JumboCodeBlock from "@jumbo/components/JumboCodeBlock"
import code from "../Grids/demo-code/spacing-grid.txt"

const SpacingGrid = () => {
  const [spacing, setSpacing] = React.useState(2)

  const jsx = `<Grid container spacing={${spacing}}>`

  return (
    <JumboDemoCard title={"Spacing Grid"} demoCode={code}>
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={spacing}>
            {[0, 1, 2].map((value) => (
              <Grid key={value} item>
                <Paper
                  sx={{
                    height: 140,
                    width: 100,
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Grid container>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">spacing</FormLabel>
                  <RadioGroup
                    name="spacing"
                    aria-label="spacing"
                    value={spacing.toString()}
                    onChange={(event) => setSpacing(Number(event.target.value))}
                    row
                  >
                    {[0, 0.5, 1, 2, 3, 4, 8, 12].map((value) => (
                      <FormControlLabel
                        key={value}
                        value={value.toString()}
                        control={<Radio />}
                        label={value.toString()}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <JumboCodeBlock text={jsx} language="jsx" />
        </Grid>
      </Grid>
    </JumboDemoCard>
  )
}
export default SpacingGrid
