export const crypto = {
  revenueSummary: [
    { month: "Jan", earning: 500, expense: 300 },
    { month: "Feb", earning: 1000, expense: 600 },
    { month: "Mar", earning: 1500, expense: 1000 },
    { month: "Apr", earning: 900, expense: 400 },
    { month: "May", earning: 200, expense: 200 },
    { month: "Jun", earning: 500, expense: 1100 },
    { month: "Jul", earning: 800, expense: 1400 },
    { month: "Aug", earning: 1400, expense: 1700 },
    { month: "Sep", earning: 2000, expense: 2000 },
    { month: "Oct", earning: 1300, expense: 400 },
    { month: "Nov", earning: 700, expense: 700 },
    { month: "Dec", earning: 1300, expense: 2100 },
  ],
}
