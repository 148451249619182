import Div from '@jumbo/shared/Div'
import { Typography } from '@mui/material'
import emptyIllustration from "app/assets/empty.svg"

const NoRowsMessage = ({ message = "Nenhum registro encontrado", image = emptyIllustration }) => {
  return <Div
    sx={{
      h: 400,
      w: 1,
      p: 3,
      py: 6,
      display: "flex",
      alignItems: "center",
      gap: 3,
      justifyContent: "center",
      flexDirection: "column",
    }}
  >
    <img
      style={{ maxHeight: "40vh", maxWidth: "50%" }}
      src={image}
      alt="Empty"
    />

    <Typography variant="h3" sx={{ color: "primary.main", m: 0 }}>
      {message}
    </Typography>
  </Div>
}

export default NoRowsMessage