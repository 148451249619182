import jwtAuthAxios from "./auth/jwtAuth"

const bankServices = {}

bankServices.getBanks = async (name) => {
  const { data } = await jwtAuthAxios.get("bancos", {
    params: {
      nome: name,
    },
  })
  return data.json
}

export default bankServices
