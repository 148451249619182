import Div from "@jumbo/shared/Div"
import {
  ArrowUpward,
  EditOutlined,
  MoreHorizOutlined,
} from "@mui/icons-material"
import { IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { useState } from "react"

const ClientMenu = ({ onEdit, onToggleStatus, clientIsActive }) => {
  const [anchorElement, setAnchorElement] = useState(null)
  const open = Boolean(anchorElement)

  const clickHandler = (event) => {
    setAnchorElement(event.currentTarget)
  }

  const closeHandler = () => {
    setAnchorElement(null)
  }

  const optionClickHandler = (key) => {
    closeHandler()

    if (key === "edit") {
      onEdit()
    } else {
      onToggleStatus()
    }
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={clickHandler}
      >
        <MoreHorizOutlined />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={closeHandler}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={optionClickHandler.bind(null, "edit")}>
          <Div sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <EditOutlined sx={{ fontSize: "1rem" }} />
            <Typography>Editar</Typography>
          </Div>
        </MenuItem>
        <MenuItem onClick={optionClickHandler.bind(null, "toggle-status")}>
          <Div sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <ArrowUpward
              sx={{
                fontSize: "1rem",
                transform: clientIsActive ? "rotate(180deg)" : "rotate(0)",
              }}
            />
            <Typography>{clientIsActive ? "Desativar" : "Ativar"}</Typography>
          </Div>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default ClientMenu
