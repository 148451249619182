import { Card, Stack, Typography } from "@mui/material"
import notFoundIllustration from "app/assets/illustrations/not-found.svg"
import { Image } from "./styles"

const NotFoundMessage = ({ message }) => {
  return (
    <Card sx={{ px: 3, py: 6 }}>
      <Stack
        gap={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Image src={notFoundIllustration} alt="Not found illustration" />

        {message && (
          <Typography variant="h3" m={0} color="primary.main">
            {message}
          </Typography>
        )}
      </Stack>
    </Card>
  )
}

export default NotFoundMessage
