export const salesData = [
  {
    name: "Orders Monthly",
    amount: "48,548",
  },
  {
    name: "Orders Weekly",
    amount: "6,478",
  },
  {
    name: "Average Revenue",
    amount: "$210,213",
  },
  {
    name: "Total Revenue",
    amount: "$692,874",
  },
  {
    name: "Total Orders",
    amount: "9,223",
  },
  {
    name: "Past Orders",
    amount: "8,543",
  },
]
export const dataItems = [
  {
    name: "Average Revenue",
    amount: 4000,
    fill: "#2295f2",
  },
  {
    name: "Total Revenue",
    amount: 3200,
    fill: "#2EB5C9",
  },
  {
    name: "Average Orders",
    amount: 2800,
    fill: "#3BD2A2",
  },
  {
    name: "Total Orders",
    amount: 2400,
    fill: "#F39711",
  },
]
export const data = [
  {
    name: "Page A",
    month: "Jan",
    sale: 1200,
  },
  {
    name: "Page B",
    month: "Feb",
    sale: 1600,
  },
  {
    name: "Page C",
    month: "Mar",
    sale: 3000,
  },
  {
    name: "Page D",
    month: "Apr",
    sale: 3600,
  },
  {
    name: "Page E",
    month: "May",
    sale: 4800,
  },
  {
    name: "Page F",
    month: "Jun",
    sale: 4200,
  },
  {
    name: "Page G",
    month: "Jul",
    sale: 4600,
  },
  {
    name: "Page C",
    month: "Aug",
    sale: 3800,
  },
  {
    name: "Page D",
    month: "Sep",
    sale: 5200,
  },
  {
    name: "Page E",
    month: "Oct",
    sale: 5800,
  },
  {
    name: "Page F",
    month: "Nov",
    sale: 6800,
  },
  {
    name: "Page G",
    month: "Dec",
    sale: 7200,
  },
]
export const menuItems = [
  {
    title: "Full report",
    slug: "report",
  },
  {
    title: "Subscribe",
    slug: "subscribe",
  },
]
