import { createBiDirectionalMap } from "app/utils/appHelpers"

const { default: jwtAuthAxios } = require("./auth/jwtAuth")

const historyServices = {}

const serviceStatusMapper = {
  inProgress: "Em Andamento",

  scheduled: "Solicitado",
  done: "Finalizado",
  cancelled: "Cancelado",
}

const serviceTypeMapper = createBiDirectionalMap({
  frete: "shipping",
  mudanca: "move",
})

const statusColorMapper = {
  Blue: "info",
  Green: "success",
  Red: "error",
  Yellow: "warning",
  Black: "info",
}

const paymentMethodMapper = createBiDirectionalMap({
  pix: "pix",
  credit_card: "creditCard",
})

const userTypeMapper = createBiDirectionalMap({
  client: "cliente",
  driver: "motorista",
})

const decodeEntry = (data) => {
  return {
    client: {
      cpf: data.cliente.cpf,
      email: data.cliente.email,
      name: data.cliente.nome,
      phone: data.cliente.telefone,
    },
    shipment: {
      dueDate: data.frete.data_planejada,
      addressFrom: data.frete.local_saida,
      addressTo: data.frete.local_chegada,
      weight: data.frete.peso_aproximado,
      helpers: data.frete.qtd_ajudante,
      status: {
        label: data.frete.status.nome,
        color: statusColorMapper[data.frete.status.cor],
      },
      type: serviceTypeMapper[data.frete.tipo],
      buildingType: data.frete.tipo_imovel,
      value: data.frete.valor_total,
    },
    driver: {
      cpf: data.motorista?.cpf,
      email: data.motorista?.email,
      name: data.motorista?.nome,
      phone: data.motorista?.telefone,
    },
    payment: {
      date: data.pagamento.data_pagamento,
      creditCard: data.pagamento.credit_card_number,
      mercadoPagoId: data.pagamento.mercado_pago_id,
      method: data.pagamento.metodo,
      status: data.pagamento.status,
    },
  }
}

historyServices.getEntry = async (id) => {
  const entry = await jwtAuthAxios.get(`/frete/admin/${id}`)

  return decodeEntry(entry.data)
}

historyServices.getDeliveryHistory = async ({
  pageSize,
  page,
  searchTerm,
  sorting,
  filters,
}) => {
  const params = {
    perPage: pageSize,
    page,
  }

  if (filters) {
    params.serviceType = serviceTypeMapper[filters.type]
    params.serviceStatus = serviceStatusMapper[filters.status]
    params.paymentMethod = paymentMethodMapper[filters.paymentMethod]
    params.paymentStatus = filters.paymentStatus
    params.userRole = userTypeMapper[filters.userRole]
  }

  if (searchTerm) {
    params.searchName = searchTerm
  }

  if (sorting) {
    params.orderBy =
      {
        name: "nome",
        date: "date",
        client: "client_name",
        driver: "driver_name",
        type: "service_type",
        status: "service_status",
        paymentMethod: "payment_method",
        paymentStatus: "payment_status",
        value: "value",
      }[sorting.field] || sorting.field
    params.direction = sorting.direction
  }

  const { data } = await jwtAuthAxios.get("/frete/admin/history", { params })

  return {
    history: data.data.map((entry) => ({
      id: entry.frete_id,
      client: entry.client_name,
      date: entry.date,
      driver: entry.driver_name,
      paymentStatus: entry.payment_status,
      status: {
        label: entry.service_status,
        color: statusColorMapper[entry.service_status_color],
      },
      type: serviceTypeMapper[entry.service_type],
      value: entry.value,
      paymentMethod: paymentMethodMapper[entry.payment_method],
      mpPaymentId: entry.mercado_pago_id,
    })),
    total: data.total,
  }
}

export default historyServices
