import { isPersonalIdValid } from "app/utils/validation"
import * as yup from "yup"

export const validationSchema = yup.object({
  email: yup
    .string("O e-mail é obrigatório")
    .email("E-mail inválido")
    .required("O e-mail é obrigatório"),
  name: yup
    .string("O nome é obrigatório")
    .required("O nome é obrigatório")
    .matches(/[^\s]+(\s[^\s]+)+/g, "Nome inválido"),
  personalId: yup
    .string("O CPF é obrigatório")
    .matches(/\d{3}\.\d{3}\.\d{3}-\d{2}/g, "CPF inválido")
    .test("test-last-digits", "CPF inválido", isPersonalIdValid),
  phone: yup
    .string("O telefone é obrigatório")
    .required("O telefone é obrigatório")
    .matches(
      /(\(\d{2}\)\s\d{4}-\d{4})|(\(\d{2}\)\s\d{5}-\d{4})/g,
      "Telefone inválido",
    ),
})
