import React from "react"
import Button from "@mui/material/Button"
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks"
import JumboDemoCard from "@jumbo/components/JumboDemoCard"
import code from "../components/demo-code/error-alert.txt"

const ErrorAlert = () => {
  const Swal = useSwalWrapper()
  const sweetAlerts = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: "<a href>Why do I have this issue?</a>",
    })
  }
  return (
    <JumboDemoCard
      title={"Error alert"}
      demoCode={code}
      wrapperSx={{ pt: 0, backgroundColor: "background.paper" }}
    >
      <Button variant={"outlined"} onClick={sweetAlerts}>
        Click me
      </Button>
    </JumboDemoCard>
  )
}

export default ErrorAlert
