const { default: jwtAuthAxios } = require("./auth/jwtAuth")

const mpServices = {}

mpServices.refundTransaction = async ({ paymentId }) => {
  const { data } = await jwtAuthAxios.post("mp/admin/reembolso", {
    pagamento_id: paymentId,
  })

  return data
}

export default mpServices
