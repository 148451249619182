export const isPersonalIdValid = (id) => {
  id = id?.replace(/[^\d]+/g, "")

  if (!id) {
    return false
  }

  if (
    id.length !== 11 ||
    id === "00000000000" ||
    id === "11111111111" ||
    id === "22222222222" ||
    id === "33333333333" ||
    id === "44444444444" ||
    id === "55555555555" ||
    id === "66666666666" ||
    id === "77777777777" ||
    id === "88888888888" ||
    id === "99999999999"
  ) {
    return false
  }

  let add = 0
  for (let i = 0; i < 9; i++) add += parseInt(id.charAt(i)) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(id.charAt(9))) return false

  add = 0
  for (let i = 0; i < 10; i++) add += parseInt(id.charAt(i)) * (11 - i)
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  return rev === parseInt(id.charAt(10))
}
