import PaymentStatusChip from "app/components/HistoryList/PaymentStatusChip"
import useHistoryEntry from "app/hooks/useHistoryEntry"
import {
  formatCPF,
  formatDateHour,
  formatPhone,
  formatToCurrency,
  getOnlyNumbers,
} from "app/utils/format"
import { useParams } from "react-router-dom"

import {
  Card,
  Chip,
  CircularProgress,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material"

function getWeightLabel(weight) {
  if (weight <= 10) {
    return "Até 10kg"
  } else if (weight <= 40) {
    return "10kg a 40kg"
  } else if (weight <= 300) {
    return "40kg a 300kg"
  } else if (weight <= 700) {
    return "300kg a 700kg"
  } else {
    return "Acima de 700kg"
  }
}

const Field = (props) => {
  return <TextField fullWidth InputProps={{ readOnly: true }} {...props} />
}

const HistoryView = () => {
  const { id } = useParams()
  const { historyEntry, isLoading } = useHistoryEntry({ id })
  const paidWithPix = historyEntry?.payment.method === "pix"
  const isShipment = historyEntry?.shipment.type === "shipping"
  return (
    <Card
      sx={{ display: "flex", flexDirection: "column", p: 3, minHeight: "70vh" }}
    >
      <Typography mb={2} variant="h1">
        Visualizar serviço
      </Typography>
      {(isLoading || !historyEntry) && (
        <CircularProgress sx={{ alignSelf: "center", my: "auto" }} />
      )}
      {!isLoading && historyEntry && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">Pagamento</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
            <Stack>
              <InputLabel shrink>Meio de Pagamento</InputLabel>
              <Typography sx={{ my: "auto" }}>
                {paidWithPix ? "Pix" : "Cartão de Crédito"}
              </Typography>
            </Stack>
            <Stack>
              <InputLabel shrink>Status</InputLabel>
              <PaymentStatusChip status={historyEntry.payment.status} />
            </Stack>
          </Grid>
          {!paidWithPix && (
            <Grid item xs={12} sm={4}>
              <Field
                label="Cartão"
                value={"**** **** **** " + historyEntry.payment.creditCard}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={4} md={2}>
            <Field
              label="ID do Mercado Pago"
              value={historyEntry.payment.mercadoPagoId}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Field
              label="Data/hora"
              value={formatDateHour(historyEntry.payment.date)}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Field
              label="Valor"
              value={formatToCurrency(historyEntry.shipment.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">Cliente</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field label="Nome" value={historyEntry.client.name} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Field label="CPF" value={formatCPF(historyEntry.client.cpf)} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field label="E-mail" value={historyEntry.client.email} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Field
              label="Telefone"
              value={formatPhone(historyEntry.client.phone)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">Motorista</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field label="Nome" value={historyEntry.driver.name} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Field label="CPF" value={formatCPF(historyEntry.driver.cpf)} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field label="E-mail" value={historyEntry.driver.email} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Field
              label="Telefone"
              value={formatPhone(historyEntry.driver.phone)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3">Serviço</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", gap: 4 }}>
            <Stack>
              <InputLabel shrink>Tipo de Serviço</InputLabel>
              <Typography sx={{ my: "auto" }}>
                {isShipment ? "Frete" : "Mudança"}
              </Typography>
            </Stack>
            <Stack>
              <InputLabel shrink>Status</InputLabel>
              <Chip
                label={historyEntry.shipment.status.label}
                color={historyEntry.shipment.status.color}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field label="Saída" value={historyEntry.shipment.addressFrom} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field label="Destino" value={historyEntry.shipment.addressTo} />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Field
              label="Data/hora"
              value={formatDateHour(historyEntry.shipment.dueDate)}
            />
          </Grid>
          {historyEntry.shipment.helpers && (
            <Grid item xs={12} sm={4} md={2}>
              <Field
                label="N.º de Ajudantes"
                value={
                  historyEntry.shipment.helpers +
                  (historyEntry.shipment.helpers === 1
                    ? "ajudante"
                    : " ajudantes")
                }
              />
            </Grid>
          )}

          <Grid item xs={12} sm={4} md={2}>
            <Field
              label="Peso Aproximado"
              value={getWeightLabel(
                getOnlyNumbers(historyEntry.shipment.weight),
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field
              label="Tipo de Imóvel"
              value={historyEntry.shipment.buildingType}
            />
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

export default HistoryView
