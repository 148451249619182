export const newsArticles = [
  { month: "Jan", count: 300 },
  { month: "Feb", count: 1650 },
  { month: "Mar", count: 1800 },
  { month: "Apr", count: 2400 },
  { month: "May", count: 1600 },
  { month: "Jun", count: 2200 },
  { month: "Jul", count: 1300 },
  { month: "Aug", count: 2800 },
  { month: "Sep", count: 2200 },
]
