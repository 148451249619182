import mpServices from "app/services/mp-services"
import { useMutation, useQueryClient } from "react-query"
import useFeedback from "./useFeedback"

const useMercadoPago = () => {
  const queryClient = useQueryClient()
  const { showToast } = useFeedback()
  const {
    mutateAsync: refundTransaction,
    data: refundResponse,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (paymentId) => mpServices.refundTransaction({ paymentId }),
    onSuccess: () => {
      queryClient.invalidateQueries("history")
      showToast({
        title: "Pagamento reembolsado com sucesso",
        icon: "success",
      })
    },
    onError: () => {
      showToast({
        title: "Não foi possível reembolsar este pagamento",
        icon: "error",
      })
    },
  })

  return { refundTransaction, refundResponse, isLoading, isError }
}

export default useMercadoPago
