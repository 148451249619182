import ApprovedDriverMenu from "app/components/DriversList/ApprovedDriverMenu"
import PendingDriverMenu from "app/components/DriversList/PendingDriverMenu"
import StatusChip from "app/components/shared/StatusChip"
import { formatCPF, formatPhone } from "app/utils/format"

const sharedColumns = [
  { field: "name", headerName: "Nome", flex: 1, minWidth: 160 },
  {
    field: "personalId",
    headerName: "CPF",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row: { personalId } }) => formatCPF(personalId),
  },
  {
    field: "email",
    headerName: "E-mail",
    width: 90,
    flex: 1,
    minWidth: 160,
  },
  {
    field: "phone",
    headerName: "Telefone",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row: { phone } }) => formatPhone(phone),
  },
]

export const getApprovedColumns = ({ onEdit, onToggleStatus }) => [
  ...sharedColumns,
  {
    field: "status",
    headerName: "Status",
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => <StatusChip isActive={row.status === "active"} />,
    flex: 1,
    minWidth: 160,
  },
  {
    field: "actions",
    headerName: "Ações",
    sortable: false,
    renderCell: ({ row }) => (
      <ApprovedDriverMenu
        onEdit={onEdit.bind(null, row)}
        onToggleStatus={onToggleStatus.bind(null, row)}
        driverIsActive={row.status === "active"}
      />
    ),
  },
]

export const getPendingColumns = ({ onEdit, onApprove, onDisapprove }) => [
  ...sharedColumns,
  {
    field: "actions",
    headerName: "Ações",
    sortable: false,
    renderCell: ({ row }) => (
      <PendingDriverMenu
        onEdit={onEdit.bind(null, row)}
        onApprove={onApprove.bind(null, row)}
        onDisapprove={onDisapprove.bind(null, row)}
      />
    ),
  },
]

export const getDisapprovedColumns = ({ onView, onApprove }) => [
  ...sharedColumns,
  {
    field: "reason",
    headerName: "Justificativa",
    width: 90,
    flex: 1,
    minWidth: 160,
  },
  {
    field: "actions",
    headerName: "Ações",
    sortable: false,
    renderCell: ({ row }) => (
      <PendingDriverMenu
        onView={onView.bind(null, row)}
        onApprove={onApprove.bind(null, row)}
      />
    ),
  },
]
