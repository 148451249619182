export const data = [
  {
    name: "Page A",
    profit: 5000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    profit: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    profit: -2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    profit: 2900,
    pv: 5308,
    amt: 2000,
  },
  {
    name: "Page E",
    profit: -2500,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    profit: 1500,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    profit: 4000,
    pv: 4300,
    amt: 2100,
  },
]
