import historyServices from "app/services/history-services"
import { useState } from "react"
import { useQuery } from "react-query"
import useFeedback from "./useFeedback"

const useDeliveryHistory = () => {
  const [sorting, setSorting] = useState()
  const [searchTerm, setSearchTerm] = useState("")
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState()

  const { showToast } = useFeedback()

  const {
    refetch: getDeliveryHistory,
    isLoading,
    data: deliveryHistory,
  } = useQuery(
    ["history", searchTerm, filters, sorting, page, pageSize],
    () =>
      historyServices.getDeliveryHistory({
        page,
        pageSize,
        searchTerm,
        sorting,
        filters,
      }),
    {
      onSuccess: (data) => {
        setTotal(data.total)
      },
      onError: () => {
        showToast({
          title:
            "Não foi possível obter o histórico no momento. Tente novamente depois!",
          icon: "error",
        })
      },
    },
  )

  return {
    getDeliveryHistory,
    setSorting,
    pageSize,
    sorting,
    setPageSize,
    total,
    setTotal,
    page,
    setPage,
    setSearchTerm,
    searchTerm,
    filters,
    setFilters,
    isLoading,
    deliveryHistory: deliveryHistory?.history,
  }
}

export default useDeliveryHistory
