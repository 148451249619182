import { Chip } from "@mui/material"

const paymentStatusMapper = {
  pending: {
    color: "info",
    label: "Processando",
  },
  refunded: {
    color: "warning",
    label: "Reembolsado",
  },
  approved: {
    color: "success",
    label: "Pago",
  },
  reproved: {
    color: "error",
    label: "Recusado",
  },
}

/**
 *
 * @param {Object} props
 * @param {'pending' | 'refunded' | 'approved' | 'refused'} props.status
 * @returns
 */
const PaymentStatusChip = ({ status }) => {
  return <Chip {...paymentStatusMapper[status]} sx={{ width: 100 }} />
}

export default PaymentStatusChip
