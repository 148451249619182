export const dealsAnalyticsData = [
  { month: "Jan", queries: 400, deals: 400 },
  { month: "Feb", queries: 500, deals: 600 },
  { month: "Mar", queries: 400, deals: 300 },
  { month: "Apr", queries: 350, deals: 200 },
  { month: "May", queries: 700, deals: 700 },
  { month: "Jun", queries: 100, deals: 600 },
  { month: "Jul", queries: 500, deals: 50 },
  { month: "Aug", queries: 350, deals: 550 },
  { month: "Sep", queries: 300, deals: 200 },
  { month: "Oct", queries: 200, deals: 500 },
  { month: "Nov", queries: 200, deals: 600 },
  { month: "Dec", queries: 200, deals: 100 },
]
