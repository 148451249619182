export const scheduleData = [
  {
    id: 1,
    title: "After effect learning",
    subTitle: "10:00 AM to 11:00 AM",
    color: "#2295f2",
  },
  {
    id: 2,
    title: "Meeting with react development team",
    subTitle: "1:00 PM to 1:30 PM",
    color: "#E44A77",
  },
  {
    id: 3,
    title: "Wireframe design for jumbo template",
    subTitle: "10:00 AM to 2:30 PM",
    color: "#3BD2A2",
  },
]
