import React from "react"
import JumboDemoCard from "@jumbo/components/JumboDemoCard"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import { standardImageDataItem } from "./data"
import code from "../ImagesList/demo-code/standard-image-list.txt"

const StandardImageList = () => {
  return (
    <JumboDemoCard
      title={"Standard Image List"}
      demoCode={code}
      wrapperSx={{
        backgroundColor: "background.paper",
        pt: 0,
      }}
    >
      <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
        {standardImageDataItem.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </JumboDemoCard>
  )
}

export default StandardImageList
