import Page from "@jumbo/shared/Page"
import ForgotPassword from "app/pages/auth-pages/forgot-password"
import ResetPassword from "app/pages/auth-pages/reset-password"
import EditClientPage from "app/pages/clients/edit/EditClient"
import ClientsListPage from "app/pages/clients/list/ClientsList"
import {
  DriversListPage,
  EditDriverPage,
  ViewDriverPage,
} from "app/pages/drivers"
import { HistoryListPage, HistoryViewPage } from "app/pages/history"
import { Navigate } from "react-router-dom"
import Login2 from "../pages/auth-pages/login2"
import appsRoutes from "./appsRoutes"
import authRoutes from "./authRoutes"
import calendarRoutes from "./calendarRoutes"
import chartRoutes from "./chartRoutes"
import dashboardRoutes from "./dashboardRoutes"
import extensionsRoutes from "./extensionsRoutes"
import extraRoutes from "./extraRoutes"
import gridViewRoutes from "./gridViewRoutes"
import listViewRoutes from "./listViewRoutes"
import mapRoutes from "./mapRoutes"
import metricsRoutes from "./metricsRoutes"
import { muiRoutes } from "./muiRoutes"
import userRoutes from "./userRoutes"
import widgetsRoutes from "./widgetRoutes"

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = []

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  {
    path: "/",
    element: <Navigate to={"/clients/list"} />,
  },
  {
    path: "/clients/list",
    element: <Page component={ClientsListPage} />,
  },
  {
    path: "/clients/edit/:id",
    element: <Page component={EditClientPage} />,
  },
  {
    path: "/drivers/list",
    element: <Page component={DriversListPage} />,
  },
  {
    path: "/drivers/edit/:id",
    element: <Page component={EditDriverPage} />,
  },
  {
    path: "/drivers/view/:id",
    element: <Page component={ViewDriverPage} />,
  },
  {
    path: "/history/list",
    element: <Page component={HistoryListPage} />,
  },
  {
    path: "/history/view/:id",
    element: <Page component={HistoryViewPage} />,
  },
  ...dashboardRoutes,
  ...appsRoutes,
  ...widgetsRoutes,
  ...metricsRoutes,
  ...muiRoutes,
  ...extensionsRoutes,
  ...calendarRoutes,
  ...chartRoutes,
  ...mapRoutes,
  ...authRoutes,
  ...extraRoutes,
  ...userRoutes,
  ...listViewRoutes,
  ...gridViewRoutes,
]

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
    path: "/auth/login",
    element: (
      <Page component={Login2} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
  {
    path: "/auth/forgot-password",
    element: <Page component={ForgotPassword} layout="solo-page" />,
  },
  {
    path: "/auth/reset-password",
    element: <Page component={ResetPassword} layout="solo-page" />,
  },
]

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
]

export {
  routes as default,
  routesForAuthenticatedOnly,
  routesForNotAuthenticatedOnly,
  routesForPublic,
}
