import { LoadingButton } from "@mui/lab"
import { Card, Stack, Typography } from "@mui/material"
import EditDriverForm from "app/components/EditDriver/EditDriverForm"
import LoadingMessage from "app/components/shared/LoadingMessage"
import NotFoundMessage from "app/components/shared/NotFoundMessage"
import useDriver from "app/hooks/useDriver"
import useDriverUpdate from "app/hooks/useDriverUpdate"
import { Link, useNavigate, useParams } from "react-router-dom"
import { validationSchema } from "./utils"
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks"
import { Check, Clear } from "@mui/icons-material"

const EditDriverPage = () => {
  const { id: driverID } = useParams()
  const { driver, isLoading: loadingDriver } = useDriver(driverID)
  const {
    updateDriver,
    isUpdatingDriver,
    isChangingStatus,
    approveDriver,
    refuseDriver,
  } = useDriverUpdate()

  const swal = useSwalWrapper()
  const navigate = useNavigate()

  if (!driver) {
    if (loadingDriver) {
      return <LoadingMessage message="Obtendo dados do motorista..." />
    } else {
      return <NotFoundMessage message="Motorista não encontrado." />
    }
  }

  const goBack = () => {
    navigate("/drivers/list", {
      replace: true,
    })
  }

  const handleDriverApproval = () => {
    swal.fire({
      icon: "question",
      title: `Aprovar motorista`,
      showCancelButton: true,
      text: `Deseja realmente aprovar a conta desse motorista?`,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await approveDriver(driverID)
          goBack()
        } catch {}
      },
    })
  }

  const handleDriverRefusal = () => {
    return swal.fire({
      icon: "question",
      title: `Reprovar motorista`,
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        try {
          await refuseDriver(driverID, reason)
          goBack()
        } catch {}
      },
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      text: `Deseja realmente reprovar a conta desse motorista?`,
      input: "text",
      inputLabel: "Justificativa",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Você precisa justificar a reprovação do motorista."
        }
      },
    })
  }

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h1">Alterar motorista</Typography>

      <EditDriverForm
        initialValues={driver}
        isUpdatingDriver={isUpdatingDriver}
        onUpdateDrivers={updateDriver}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, dirty }) => (
          <Stack
            direction="row"
            width="100%"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: 2 }}
          >
            <Link href={isSubmitting ? "#" : "/drivers/list"}>
              <LoadingButton disabled={isSubmitting}>Cancelar</LoadingButton>
            </Link>
            <LoadingButton
              variant="contained"
              type={"submit"}
              loading={isUpdatingDriver}
              disabled={!dirty}
            >
              Salvar
            </LoadingButton>
            {driver?.driverStatus === "pending" && (
              <>
                <LoadingButton
                  endIcon={<Clear />}
                  variant="contained"
                  type="button"
                  color="error"
                  loading={isChangingStatus}
                  onClick={handleDriverRefusal}
                >
                  Reprovar
                </LoadingButton>
                <LoadingButton
                  endIcon={<Check />}
                  variant="contained"
                  type="button"
                  color="success"
                  onClick={handleDriverApproval}
                  loading={isChangingStatus}
                >
                  Aprovar
                </LoadingButton>
              </>
            )}
          </Stack>
        )}
      </EditDriverForm>
    </Card>
  )
}

export default EditDriverPage
