import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks"
import { Check, Clear } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Card, Stack, Typography } from "@mui/material"
import EditDriverForm from "app/components/EditDriver/EditDriverForm"
import LoadingMessage from "app/components/shared/LoadingMessage"
import NotFoundMessage from "app/components/shared/NotFoundMessage"
import useDriver from "app/hooks/useDriver"
import useDriverUpdate from "app/hooks/useDriverUpdate"
import { useNavigate, useParams } from "react-router-dom"

const ViewDriverPage = () => {
  const { id: driverID } = useParams()
  const { driver, isLoading: loadingDriver } = useDriver(driverID)
  const { approveDriver, refuseDriver, isUpdatingDriver, isChangingStatus } =
    useDriverUpdate()
  const navigate = useNavigate()
  const swal = useSwalWrapper()

  if (!driver) {
    if (loadingDriver) {
      return <LoadingMessage message="Obtendo dados do motorista..." />
    } else {
      return <NotFoundMessage message="Motorista não encontrado." />
    }
  }

  const goBack = () => {
    navigate("/drivers/list", {
      replace: true,
    })
  }
  const handleDriverApproval = () => {
    swal.fire({
      icon: "question",
      title: `Aprovar motorista`,
      showCancelButton: true,
      text: `Deseja realmente aprovar a conta desse motorista?`,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await approveDriver(driverID)
          goBack()
        } catch {}
      },
    })
  }

  const handleDriverRefusal = () => {
    return swal.fire({
      icon: "question",
      title: `Reprovar motorista`,
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        try {
          await refuseDriver(driverID, reason)
          goBack()
        } catch {}
      },
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      text: `Deseja realmente reprovar a conta desse motorista?`,
      input: "text",
      inputLabel: "Justificativa",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Você precisa justificar a reprovação do motorista."
        }
      },
    })
  }
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h1">Visualizar motorista</Typography>
      <EditDriverForm
        initialValues={driver}
        isUpdatingDriver={isUpdatingDriver}
        readOnly
      >
        <Stack
          direction="row"
          width="100%"
          spacing={4}
          justifyContent="flex-end"
          sx={{ mt: 2 }}
        >
          <LoadingButton disabled={isChangingStatus} onClick={goBack}>
            Cancelar
          </LoadingButton>
          <Stack direction="row" gap={2}>
            {driver?.driverStatus !== "refused" && (
              <LoadingButton
                endIcon={<Clear />}
                variant="contained"
                type="submit"
                color="error"
                loading={isChangingStatus}
                onClick={handleDriverRefusal}
              >
                Reprovar
              </LoadingButton>
            )}
            <LoadingButton
              endIcon={<Check />}
              variant="contained"
              type="submit"
              color="success"
              onClick={handleDriverApproval}
              loading={isChangingStatus}
            >
              Aprovar
            </LoadingButton>
          </Stack>
        </Stack>
      </EditDriverForm>
    </Card>
  )
}

export default ViewDriverPage
