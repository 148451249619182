import { ASSET_AVATARS } from "../../../utils/constants/paths"
import { getAssetPath } from "../../../utils/appHelpers"

export const customers = [
  {
    id: 1,
    profilePic: getAssetPath(`${ASSET_AVATARS}/michael-dogov.jpg`, `48x48`),
    title: "Albert Hall",
    desc: "It's very engaging. Right?",
  },
  {
    id: 2,
    profilePic: getAssetPath(`${ASSET_AVATARS}/jeson-born.jpg`, `48x48`),
    title: "Albert Hall",
    desc: "It's very engaging. Right?",
  },
  {
    id: 3,
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, `48x48`),
    title: "Albert Hall",
    desc: "It's very engaging. Right?",
  },
  {
    id: 4,
    profilePic: getAssetPath(`${ASSET_AVATARS}/stella-johnson.png`, `48x48`),
    title: "Albert Hall",
    desc: "It's very engaging. Right?",
  },
  {
    id: 5,
    profilePic: getAssetPath(`${ASSET_AVATARS}/jimmy-jo.jpg`, `48x48`),
    title: "Albert Hall",
    desc: "It's very engaging. Right?",
  },
  {
    id: 6,
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, `48x48`),
    title: "Albert Hall",
    desc: "It's very engaging. Right?",
  },
  {
    id: 7,
    profilePic: getAssetPath(`${ASSET_AVATARS}/avatar7.jpg`, `48x48`),
    title: "Albert Hall",
    desc: "It's very engaging. Right?",
  },
]
