import React from "react"
import JumboDemoCard from "@jumbo/components/JumboDemoCard"
import ImageList from "@mui/material/ImageList"
import { quiltedImageDataItem } from "./data"
import ImageListItem from "@mui/material/ImageListItem"
import code from "../ImagesList/demo-code/quilted-image-list.txt"

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  }
}

const QuiltedImageList = () => {
  return (
    <JumboDemoCard
      title={"Quilted Image List"}
      demoCode={code}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <ImageList
        sx={{ width: 500, height: 450 }}
        variant="quilted"
        cols={4}
        rowHeight={121}
      >
        {quiltedImageDataItem.map((item) => (
          <ImageListItem
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            <img
              {...srcset(item.img, 121, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </JumboDemoCard>
  )
}

export default QuiltedImageList
