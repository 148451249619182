export const headerChartData = [
  { name: "Jan", Income: 12000, Expanse: 3000 },
  { name: "Feb", Income: 45000, Expanse: 30000 },
  { name: "Mar", Income: 7000, Expanse: 27000 },
  { name: "Apr", Income: 35000, Expanse: 17000 },
  { name: "May", Income: 33000, Expanse: 22000 },
  { name: "Jun", Income: 34000, Expanse: 16000 },
  { name: "Jul", Income: 45000, Expanse: 19000 },
  { name: "Aug", Income: 10000, Expanse: 2000 },
  { name: "Sep", Income: 25000, Expanse: 14000 },
  { name: "Oct", Income: 6000, Expanse: 3000 },
  { name: "Nov", Income: 50000, Expanse: 20000 },
  { name: "Dec", Income: 43000, Expanse: 4000 },
  { name: "", Income: 10000, Expanse: 2000 },
]
