import { ASSET_IMAGES } from "../../../utils/constants/paths"
import { getAssetPath } from "../../../utils/appHelpers"

export const userPhotos = [
  {
    id: 1,
    photo_url: getAssetPath(`${ASSET_IMAGES}/products/speaker.jpeg`, "400x400"),
    caption: "Beauty with Beast",
    thumb: "",
    size: 2.5,
  },
  {
    id: 2,
    photo_url: getAssetPath(`${ASSET_IMAGES}/products/laptop.jpeg`, "400x400"),
    caption: "Nature Love",
    thumb: "",
    size: 2,
  },
  {
    id: 3,
    photo_url: getAssetPath(`${ASSET_IMAGES}/products/trimmer.jpg`, "400x400"),
    caption: "Forest",
    thumb: "",
    size: 3.5,
  },
  {
    id: 4,
    photo_url: getAssetPath(
      `${ASSET_IMAGES}/products/travel-bag.jpg`,
      "400x400",
    ),
    caption: "Nature at its best",
    thumb: "",
    size: 5,
  },
  {
    id: 5,
    photo_url: getAssetPath(
      `${ASSET_IMAGES}/products/microphone.jpeg`,
      "400x400",
    ),
    caption: "Sea House",
    thumb: "",
    size: 3.5,
  },
  {
    id: 6,
    photo_url: getAssetPath(`${ASSET_IMAGES}/products/speaker.jpeg`, "400x400"),
    caption: "Minimal",
    thumb: "",
    size: 4,
  },
  {
    id: 7,
    photo_url: getAssetPath(`${ASSET_IMAGES}/products/watch.jpeg`, "400x400"),
    caption: "Beauty with Beast",
    thumb: "",
    size: 2.5,
  },
  {
    id: 8,
    photo_url: getAssetPath(`${ASSET_IMAGES}/products/led.jpeg`, "400x400"),
    caption: "Nature Love",
    thumb: "",
    size: 2,
  },
  {
    id: 9,
    photo_url: getAssetPath(`${ASSET_IMAGES}/products/iPhone.jpeg`, "400x400"),
    caption: "Forest",
    thumb: "",
    size: 3.5,
  },
  {
    id: 10,
    photo_url: getAssetPath(
      `${ASSET_IMAGES}/products/headphone.jpeg`,
      "400x400",
    ),
    caption: "Nature at its best",
    thumb: "",
    size: 5,
  },
  {
    id: 11,
    photo_url: getAssetPath(`${ASSET_IMAGES}/products/iPhone.jpeg`, "400x400"),
    caption: "Sea House",
    thumb: "",
    size: 3.5,
  },
  {
    id: 12,
    photo_url: getAssetPath(`${ASSET_IMAGES}/products/speaker.jpeg`, "400x400"),
    caption: "Minimal",
    thumb: "",
    size: 4,
  },
]
