import React from "react"
import JumboDemoCard from "@jumbo/components/JumboDemoCard"
import ImageList from "@mui/material/ImageList"
import { titleBarBelowImageDataItem } from "./data"
import ImageListItem from "@mui/material/ImageListItem"
import { ImageListItemBar } from "@mui/material"
import code from "../ImagesList/demo-code/titlebar-below-image-list.txt"

const TitlebarBelowImageList = () => {
  return (
    <JumboDemoCard
      title={"Title bar below image (standard)"}
      demoCode={code}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <ImageList sx={{ width: 500, height: 450 }}>
        {titleBarBelowImageDataItem.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.title}
              subtitle={<span>by: {item.author}</span>}
              position="below"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </JumboDemoCard>
  )
}

export default TitlebarBelowImageList
