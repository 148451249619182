import { isPersonalIdValid } from "app/utils/validation"
import * as yup from "yup"
import { isCNH } from "validation-br"

export const validationSchema = {
  fields: {
    email: yup
      .string("O e-mail é obrigatório")
      .email("E-mail inválido")
      .required("O e-mail é obrigatório"),
    name: yup
      .string("O nome é obrigatório")
      .required("O nome é obrigatório")
      .matches(/[^\s]+(\s[^\s]+)+/g, "Nome inválido"),
    personalId: yup
      .string("O CPF é obrigatório")
      .matches(/\d{3}\.\d{3}\.\d{3}-\d{2}/g, "CPF inválido")
      .test("test-last-digits", "CPF inválido", isPersonalIdValid),
    phone: yup
      .string("O telefone é obrigatório")
      .required("O telefone é obrigatório")
      .matches(
        /(\(\d{2}\)\s\d{4}-\d{4})|(\(\d{2}\)\s\d{5}-\d{4})/g,
        "Telefone inválido",
      ),
    pixKeyType: yup.mixed().when(["bank", "pixKey", "agency", "account"], {
      is: (...args) => args.some(Boolean),
      then: (schema) =>
        schema
          .oneOf(["email", "aleatorio", "telefone", "cpf"])
          .required(
            "O tipo da chave Pix é obrigatório para cadastro de dados bancários",
          ),
    }),
    pixKey: yup
      .string()
      .when("pixKeyType", {
        is: "aleatorio",
        then: (schema) =>
          schema.matches(
            /[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}/g,
            "Chave aleatória inválida",
          ),
      })
      .when("pixKeyType", {
        is: "telefone",
        then: (schema) =>
          schema.matches(
            /(\(\d{2}\)\s\d{4}-\d{4})|(\(\d{2}\)\s\d{5}-\d{4})/g,
            "Telefone inválido",
          ),
      })
      .when("pixKeyType", {
        is: "cpf",
        then: (schema) =>
          schema
            .matches(/\d{3}\.\d{3}\.\d{3}-\d{2}/g, "CPF inválido")
            .test("test-last-digits", "CPF inválido", isPersonalIdValid),
      })
      .when("pixKeyType", {
        is: "email",
        then: (schema) => schema.email("E-mail inválido"),
      })
      .when(["bank", "pixKeyType", "agency", "account"], {
        is: (...args) => args.some(Boolean),
        then: (schema) =>
          schema.required(
            "A chave Pix é obrigatória para cadastro de dados bancários",
          ),
      }),
    agency: yup.string().when(["bank", "pixKeyType", "pixKey", "account"], {
      is: (...args) => args.some(Boolean),
      then: (schema) =>
        schema
          .matches(/^\d{4}(-\d)?$/, "Agência inválida")
          .required("Agência é obrigatória para cadastro de dados bancários"),
    }),
    account: yup.string().when(["bank", "pixKeyType", "agency", "pixKey"], {
      is: (...args) => args.some(Boolean),
      then: (schema) =>
        schema
          .matches(/^(\d){4,11}-\d$/, "Conta inválida")
          .required("Conta é obrigatória para cadastro de dados bancários"),
    }),
    bank: yup
      .object()
      .nullable(true)
      .when(["pixKey", "pixKeyType", "agency", "account"], {
        is: (...args) => args.some(Boolean),
        then: (schema) =>
          schema
            .nullable(false)
            .required("Banco é obrigatório para cadastro de dados bancários"),
      }),
    cnh: yup
      .string()
      .required("CNH é obrigatória")
      .test("test-cnh", "CNH inválida", isCNH),
    model: yup.string().required("Modelo é obrigatório"),
    cnhImage: yup.mixed().required(),
    crlvImage: yup.mixed().required(),
    licensePlate: yup
      .string()
      .min(7, "Placa inválida")
      .required("Placa é obrigatória"),
    vehicleType: yup.string().required("Tipo é obrigatório"),
    address: yup
      .string("O endereço é obrigatório")
      .required("O endereço é obrigatório"),
    number: yup
      .string("O número é obrigatório")
      .required("O número é obrigatório")
      .matches(/^\d+$/g, "Número inválido"),
    city: yup
      .string("A cidade é obrigatória")
      .required("A cidade é obrigatória"),
  },
  edges: [
    ["agency", "account"],
    ["agency", "bank"],
    ["agency", "pixKey"],
    ["agency", "pixKeyType"],
    ["account", "agency"],
    ["account", "bank"],
    ["account", "pixKey"],
    ["account", "pixKeyType"],
    ["bank", "account"],
    ["bank", "agency"],
    ["bank", "pixKey"],
    ["bank", "pixKeyType"],
    ["pixKey", "account"],
    ["pixKey", "agency"],
    ["pixKey", "pixKeyType"],
    ["pixKey", "bank"],
    ["pixKeyType", "account"],
    ["pixKeyType", "agency"],
    ["pixKeyType", "pixKey"],
    ["pixKeyType", "bank"],
  ],
}
