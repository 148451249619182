import historyServices from "app/services/history-services"
import { useQuery } from "react-query"
import useFeedback from "./useFeedback"

const useHistoryEntry = ({ id }) => {
  const { showToast } = useFeedback()
  const {
    data: historyEntry,
    isLoading,
    isError,
  } = useQuery(["history", id], () => historyServices.getEntry(id), {
    onError: () => {
      showToast({
        title: "Não foi possível obter dados do serviço",
        icon: "error",
      })
    },
  })

  return { historyEntry, isLoading, isError }
}

export default useHistoryEntry
