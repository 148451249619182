import React from "react"
import UserProfile from "../pages/users/user-profile"
import WallApp from "../pages/users/wall-app"
import Page from "@jumbo/shared/Page"

const userRoutes = [
  {
    path: "/user/profile",
    element: <Page component={UserProfile} />,
  },
  {
    path: "/user/social-wall",
    element: <Page component={WallApp} />,
  },
]
export default userRoutes
