import jwtAxios from "axios"

const jwtAuthAxios = jwtAxios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
})

export const setAuthToken = (token) => {
  if (token) {
    jwtAuthAxios.defaults.headers.common["Authorization"] = "Bearer " + token
    sessionStorage.setItem("token", token)
  } else {
    delete jwtAuthAxios.defaults.headers.common["Authorization"]
    sessionStorage.removeItem("token")
  }
}

export const getAuthToken = () => {
  return sessionStorage.getItem("token")
}

export default jwtAuthAxios
