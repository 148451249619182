import { getAssetPath } from "../../../../utils/appHelpers"

export const standardImageDataItem = [
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      "164x164",
    ),
    title: "Breakfast",
    author: "@bkristastucchio",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      "164x164",
    ),
    title: "Burger",
    author: "@rollelflex_graphy726",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      "164x164",
    ),
    title: "Camera",
    author: "@helloimnik",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      "164x164",
    ),
    title: "Coffee",
    author: "@nolanissac",
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      "164x164",
    ),
    title: "Hats",
    author: "@hjrc33",
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      "164x164",
    ),
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      "164x164",
    ),
    title: "Basketball",
    author: "@tjdragotta",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      "164x164",
    ),
    title: "Fern",
    author: "@katie_wasserman",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      "164x164",
    ),
    title: "Mushrooms",
    author: "@silverdalex",
    rows: 2,
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      "164x164",
    ),
    title: "Tomato basil",
    author: "@shelleypauls",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      "164x164",
    ),
    title: "Sea star",
    author: "@peterlaster",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      "164x164",
    ),
    title: "Bike",
    author: "@southside_customs",
    cols: 2,
  },
]

export const quiltedImageDataItem = [
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      "242x242",
    ),
    title: "Breakfast",
    author: "@bkristastucchio",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      "121x121",
    ),
    title: "Burger",
    author: "@rollelflex_graphy726",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      "121x121",
    ),
    title: "Camera",
    author: "@helloimnik",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      "242x121",
    ),
    title: "Coffee",
    author: "@nolanissac",
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      "242x121",
    ),
    title: "Hats",
    author: "@hjrc33",
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      "242x242",
    ),
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      "121x121",
    ),
    title: "Basketball",
    author: "@tjdragotta",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      "121x121",
    ),
    title: "Fern",
    author: "@katie_wasserman",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      "242x242",
    ),
    title: "Mushrooms",
    author: "@silverdalex",
    rows: 2,
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      "121x121",
    ),
    title: "Tomato basil",
    author: "@shelleypauls",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      "121x121",
    ),
    title: "Sea star",
    author: "@peterlaster",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      "242x121",
    ),
    title: "Bike",
    author: "@southside_customs",
    cols: 2,
  },
]
export const titleBarImageDataItem = [
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      "248x166",
    ),
    title: "Breakfast",
    author: "@bkristastucchio",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      "248x166",
    ),
    title: "Burger",
    author: "@rollelflex_graphy726",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      "248x166",
    ),
    title: "Camera",
    author: "@helloimnik",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      "248x166",
    ),
    title: "Coffee",
    author: "@nolanissac",
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      "248x166",
    ),
    title: "Hats",
    author: "@hjrc33",
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      "248x166",
    ),
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      "248x166",
    ),
    title: "Basketball",
    author: "@tjdragotta",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      "248x166",
    ),
    title: "Fern",
    author: "@katie_wasserman",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      "248x166",
    ),
    title: "Mushrooms",
    author: "@silverdalex",
    rows: 2,
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      "248x166",
    ),
    title: "Tomato basil",
    author: "@shelleypauls",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      "248x166",
    ),
    title: "Sea star",
    author: "@peterlaster",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      "248x166",
    ),
    title: "Bike",
    author: "@southside_customs",
    cols: 2,
  },
]
export const titleBarBelowImageDataItem = [
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      "248x166",
    ),
    title: "Breakfast",
    author: "@bkristastucchio",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      "248x166",
    ),
    title: "Burger",
    author: "@rollelflex_graphy726",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      "248x166",
    ),
    title: "Camera",
    author: "@helloimnik",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      "248x166",
    ),
    title: "Coffee",
    author: "@nolanissac",
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      "248x166",
    ),
    title: "Hats",
    author: "@hjrc33",
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      "248x166",
    ),
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      "248x166",
    ),
    title: "Basketball",
    author: "@tjdragotta",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      "248x166",
    ),
    title: "Fern",
    author: "@katie_wasserman",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      "248x166",
    ),
    title: "Mushrooms",
    author: "@silverdalex",
    rows: 2,
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      "248x166",
    ),
    title: "Tomato basil",
    author: "@shelleypauls",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      "248x166",
    ),
    title: "Sea star",
    author: "@peterlaster",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      "248x166",
    ),
    title: "Bike",
    author: "@southside_customs",
    cols: 2,
  },
]
export const customImageDataItem = [
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      "500x400",
    ),
    title: "Breakfast",
    author: "@bkristastucchio",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      "250x200",
    ),
    title: "Burger",
    author: "@rollelflex_graphy726",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      "250x200",
    ),
    title: "Camera",
    author: "@helloimnik",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      "250x200",
    ),
    title: "Coffee",
    author: "@nolanissac",
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      "250x200",
    ),
    title: "Hats",
    author: "@hjrc33",
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      "500x400",
    ),
    title: "Honey",
    author: "@arwinneil",
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      "250x200",
    ),
    title: "Basketball",
    author: "@tjdragotta",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      "250x200",
    ),
    title: "Fern",
    author: "@katie_wasserman",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      "250x200",
    ),
    title: "Mushrooms",
    author: "@silverdalex",
    rows: 2,
    cols: 2,
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      "250x200",
    ),
    title: "Tomato basil",
    author: "@shelleypauls",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      "250x200",
    ),
    title: "Sea star",
    author: "@peterlaster",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      "250x200",
    ),
    title: "Bike",
    author: "@southside_customs",
    cols: 2,
  },
]

export const wovenImageDataItem = [
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1549388604-817d15aa0110",
      "162x242",
    ),
    title: "Bed",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
      "162x108",
    ),
    title: "Kitchen",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
      "162x242",
    ),
    title: "Sink",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
      "162x162",
    ),
    title: "Books",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
      "162x242",
    ),
    title: "Chairs",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
      "162x108",
    ),
    title: "Candle",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
      "162x216",
    ),
    title: "Laptop",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
      "162x116",
    ),
    title: "Doors",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
      "162x216",
    ),
    title: "Coffee",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
      "162x108",
    ),
    title: "Storage",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
      "162x242",
    ),
    title: "Coffee table",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
      "162x108",
    ),
    title: "Blinds",
  },
]

export const masonryImageDataItem = [
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1549388604-817d15aa0110",
      "248x372",
    ),
    title: "Bed",
    author: "swabdesign",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
      "248x248",
    ),
    title: "Books",
    author: "Pavel Nekoranec",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
      "248x372",
    ),
    title: "Sink",
    author: "Charles Deluvio",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
      "248x166",
    ),
    title: "Kitchen",
    author: "Christian Mackie",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
      "248x160",
    ),
    title: "Blinds",
    author: "Darren Richardson",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
      "248x372",
    ),
    title: "Chairs",
    author: "Taylor Simpson",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
      "248x331",
    ),
    title: "Laptop",
    author: "Ben Kolde",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
      "248x180",
    ),
    title: "Doors",
    author: "Philipp Berndt",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
      "248x332",
    ),
    title: "Coffee",
    author: "Jen P.",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
      "248x165",
    ),
    title: "Storage",
    author: "Douglas Sheppard",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
      "248x165",
    ),
    title: "Candle",
    author: "Fi Bell",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
      "248x372",
    ),
    title: "Coffee table",
    author: "Hutomo Abrianto",
  },
]

export const titleBarBelowMasonryImageDataItem = [
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1549388604-817d15aa0110",
      "248x372",
    ),
    title: "Bed",
    author: "swabdesign",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
      "248x248",
    ),
    title: "Books",
    author: "Pavel Nekoranec",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
      "248x372",
    ),
    title: "Sink",
    author: "Charles Deluvio",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
      "248x166",
    ),
    title: "Kitchen",
    author: "Christian Mackie",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
      "248x160",
    ),
    title: "Blinds",
    author: "Darren Richardson",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
      "248x372",
    ),
    title: "Chairs",
    author: "Taylor Simpson",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
      "248x331",
    ),
    title: "Laptop",
    author: "Ben Kolde",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
      "248x180",
    ),
    title: "Doors",
    author: "Philipp Berndt",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
      "248x332",
    ),
    title: "Coffee",
    author: "Jen P.",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
      "248x165",
    ),
    title: "Storage",
    author: "Douglas Sheppard",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
      "248x165",
    ),
    title: "Candle",
    author: "Fi Bell",
  },
  {
    img: getAssetPath(
      "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
      "248x372",
    ),
    title: "Coffee table",
    author: "Hutomo Abrianto",
  },
]
