import React, { useState } from "react"
import { Alert, Card, CardContent, Link, Typography } from "@mui/material"
import Div from "@jumbo/shared/Div"
import { alpha } from "@mui/material/styles"
import * as yup from "yup"
import { Form, Formik } from "formik"
import { LoadingButton } from "@mui/lab"
import authServices from "app/services/auth-services"
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import PasswordField from "app/components/shared/PasswordField"

const validationSchema = yup.object({
  password: yup
    .string("A senha é obrigatória")
    .matches(/.*[A-Z].*/g, "A senha precisa conter uma letra maiúscula")
    .matches(/.*[a-z].*/g, "A senha precisa conter uma letra minúscula")
    .matches(/.*[0-9].*/g, "A senha precisa conter um número")
    .matches(
      /.*[^a-zA-Z0-9].*/g,
      "A senha precisa conter um caractere especial",
    )
    .min(8, "A senha precisa ter pelo menos 8 caracteres")
    .required("A senha é obrigatória"),
  passwordConfirmation: yup
    .string("A confirmação da senha é obrigatória")
    .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
    .required("A confirmação da senha é obrigatória"),
})

const ResetPassword = () => {
  const [error, setError] = useState("")

  const Swal = useSwalWrapper()

  const navigate = useNavigate()

  const token = new URLSearchParams(useLocation().search).get("token")

  const onResetPassword = async (password, passwordConfirmation) => {
    try {
      await authServices.resetPassword({
        passwordConfirmation,
        password,
        token,
      })

      Swal.fire({
        title: "Senha atualizada!",
        text: "Senha atualizada com sucesso",
        icon: "success",
        confirmButtonText: "Ok",
      }).then(() => {
        navigate("/auth/login")
      })
    } catch {
      setError("O token de recuperação de senha é inválido")
    }
  }

  return (
    <Div sx={{ width: 720, maxWidth: "100%", margin: "auto", p: 4 }}>
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            flex: "0 1 300px",
            position: "relative",
            backgroundSize: "cover",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: alpha("#0267a0", 0.65),
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: "100%",
              minHeight: { md: 320 },
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={"h3"}
                color={"inherit"}
                fontWeight={500}
                mb={3}
              >
                Redefinir Senha
              </Typography>
              <Typography variant={"body1"} mb={1} sx={{ maxWidth: 270 }}>
                Preencha o formulário ao lado para redefinir sua senha
              </Typography>
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Formik
              validateOnChange={true}
              initialValues={{ password: "", passwordConfirmation: "" }}
              validationSchema={validationSchema}
              onSubmit={async (data, { setSubmitting }) => {
                setError("")
                setSubmitting(true)
                await onResetPassword(data.password, data.passwordConfirmation)
                setSubmitting(false)
              }}
            >
              {({ isSubmitting, dirty, isValid, setTouched }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                  onChange={setError.bind(null, "")}
                >
                  <Div sx={{ mt: 1, mb: 3 }}>
                    <PasswordField
                      fullWidth
                      name="password"
                      label="Nova senha"
                      InputLabelProps={{ htmlFor: "password" }}
                      InputProps={{ id: "password" }}
                    />
                  </Div>

                  <Div sx={{ mt: 1, mb: 3 }}>
                    <PasswordField
                      fullWidth
                      name="passwordConfirmation"
                      label="Confirme a senha"
                      InputLabelProps={{ htmlFor: "passwordConfirmation" }}
                      InputProps={{ id: "passwordConfirmation" }}
                    />
                  </Div>

                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 1 }}
                    loading={isSubmitting}
                    disabled={!dirty || !isValid}
                    onClick={setTouched.bind(null, { email: true })}
                    data-testid="submit-button"
                  >
                    Redefinir senha
                  </LoadingButton>
                </Form>
              )}
            </Formik>

            <Link underline="none" href="/auth/login" sx={{ mt: 1 }}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="text"
                size="large"
              >
                Voltar
              </LoadingButton>
            </Link>

            {error ? (
              <Div sx={{ mt: 3 }}>
                <Alert severity="error" data-testid="error-message">
                  {error}
                </Alert>
              </Div>
            ) : null}
          </Div>
        </CardContent>
      </Card>
    </Div>
  )
}

export default ResetPassword
