import { ASSET_IMAGES } from "../../../utils/constants/paths"

export const applicationsData = {
  colors: ["#0795F4", "#8DCD03", "#23036A"],
  pieData: [
    {
      name: "Windows",
      value: 25.85,
    },
    {
      name: "Apple",
      value: 50.85,
    },
    {
      name: "Android ",
      value: 25.91,
    },
  ],
  applicationsList: [
    `${ASSET_IMAGES}/dashboard/window.png`,
    `${ASSET_IMAGES}/dashboard/apple.png`,
    `${ASSET_IMAGES}/dashboard/android.png`,
  ],
}
