import driverServices from "app/services/driver-services"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import useFeedback from "./useFeedback"

/**
 * @typedef {('aprovado'|'reprovado'|'pendente')} AccountStatus
 *
 */
const useDrivers = () => {
  const queryClient = useQueryClient()
  const [accountStatus, setAccountStatus] = useState(
    /** @type {AccountStatus} */ ("aprovado"),
  )
  const [sorting, setSorting] = useState()
  const [searchTerm, setSearchTerm] = useState(undefined)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)

  const { isLoading: loadingDrivers, data } = useQuery(
    ["drivers", page, sorting, pageSize, searchTerm, accountStatus],
    () =>
      driverServices.getDrivers({
        pageSize,
        page,
        searchTerm,
        sorting,
        accountStatus,
      }),
    {
      onSuccess: (data) => {
        setTotal(data.total)
      },
    },
  )
  const { showToast } = useFeedback()

  const { mutate: toggleStatus } = useMutation({
    mutationFn: (driver) =>
      driverServices.toggleDriverStatus({
        id: driver.id,
        status: driver.status === "active" ? "inactive" : "active",
      }),
    onError: () => {
      showToast({
        title:
          "Não foi possível alterar o motorista no momento. Tente novamente mais tarde",
        icon: "error",
      })
    },
    onSuccess: (data, { id, status }) => {
      queryClient.invalidateQueries({ queryKey: ["drivers"] })
      showToast({
        title: `Motorista ${
          status === "active" ? "inativado" : "ativado"
        } com sucesso`,
        icon: "success",
      })
    },
  })

  return {
    drivers: data?.drivers,
    pageSize,
    setPageSize,
    total,
    page,
    setPage,
    sorting,
    setSorting,
    searchTerm,
    setSearchTerm,
    toggleStatus,
    accountStatus,
    setAccountStatus,
    loadingDrivers,
  }
}

export default useDrivers
