import { Chip } from "@mui/material"
import EntryMenu from "app/components/HistoryList/EntryMenu"
import PaymentStatusChip from "app/components/HistoryList/PaymentStatusChip"
import { formatToCurrency } from "app/utils/format"
import { format } from "date-fns"

const deliveryTypeMapper = {
  move: "Mudança",
  shipping: "Frete",
}

const paymentMethodMapper = {
  pix: "Pix",
  creditCard: "Cartão de Crédito",
}

export const getColumns = ({ onView, onRefund }) => [
  {
    field: "client",
    headerName: "Cliente",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "driver",
    headerName: "Motorista",
    width: 90,
    flex: 1,
    minWidth: 160,
  },
  {
    field: "type",
    headerName: "Tipo",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row: { type } }) => deliveryTypeMapper[type],
  },
  {
    field: "date",
    headerName: "Data/hora",
    align: "center",
    headerAlign: "center",
    renderCell: ({ row: { date } }) =>
      format(new Date(date), "dd/MM/yyyy HH:mm"),
    flex: 1,
    minWidth: 160,
  },
  {
    field: "status",
    headerName: "Status",
    align: "center",
    headerAlign: "center",
    renderCell: ({ row: { status } }) => (
      <Chip label={status.label} color={status.color} sx={{ minWidth: 110 }} />
    ),
    flex: 1,
    minWidth: 160,
  },
  {
    field: "value",
    headerName: "Valor",
    align: "center",
    headerAlign: "center",
    renderCell: ({ row: { value } }) => formatToCurrency(value),
    flex: 1,
    minWidth: 160,
  },
  {
    field: "paymentMethod",
    headerName: "Meio de pagamento",
    align: "center",
    headerAlign: "center",
    renderCell: ({ row: { paymentMethod } }) =>
      paymentMethodMapper[paymentMethod],
    flex: 1,
    minWidth: 160,
  },
  {
    field: "paymentStatus",
    headerName: "Status do pagamento",
    align: "center",
    headerAlign: "center",
    renderCell: ({ row: { paymentStatus } }) => (
      <PaymentStatusChip status={paymentStatus} />
    ),
    flex: 1,
    minWidth: 160,
  },
  {
    field: "actions",
    headerName: "Ações",
    sortable: false,
    renderCell: ({ row }) => (
      <EntryMenu
        onView={onView.bind(null, row)}
        onRefund={
          row.paymentStatus !== "refunded"
            ? onRefund.bind(null, row)
            : undefined
        }
      />
    ),
  },
]
