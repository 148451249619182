export const statisticsGraphData = [
  { month: "Jan", price: 200 },
  { month: "Feb", price: 300 },
  { month: "Mar", price: 550 },
  { month: "Apr", price: 500 },
  { month: "May", price: 700 },
  { month: "Jun", price: 450 },
  { month: "Jul", price: 770 },
  { month: "Aug", price: 900 },
]
