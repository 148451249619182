import JumboSearch from "@jumbo/components/JumboSearch"
import Div from "@jumbo/shared/Div"
import { Card } from "@mui/material"
import { DataGrid, ptBR } from "@mui/x-data-grid"
import LoadingMessage from 'app/components/shared/LoadingMessage'
import NoRowsMessage from 'app/components/shared/NoRowsMessage'
import useClients from "app/hooks/useClients"
import useDebounce from "app/hooks/useDebounce"
import useFeedback from "app/hooks/useFeedback"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getColumns } from "./utils"

const ClientsListPage = () => {
  const {
    clients,
    getClients,
    loadingState,
    setPage,
    setPageSize,
    pageSize,
    page,
    total: totalClients,
    setSearchTerm,
    sorting,
    setSorting,
    toggleStatus
  } = useClients()

  const navigate = useNavigate()

  const { showDialog } = useFeedback()

  useEffect(() => {
    getClients()
  }, [getClients])

  const searchHandler = useDebounce((term) => {
    setSearchTerm(term)
  }, 500)

  const editHandler = (client) => {
    navigate(`/clients/edit/${client.id}`)
  }

  const sortHandler = ([data]) => {
    setSorting(
      data
        ? {
            field: data.field,
            direction: data.sort,
          }
        : undefined,
    )
  }

  const toggleStatusHandler = async (client) => {
    const isActive = client.status === "active"

    const result = await showDialog({
      icon: "question",
      title: `${isActive ? "Desativar" : "Ativar"} cliente`,
      text: `Deseja realmente ${
        isActive ? "inativar" : "ativar"
      } esse cliente?`,
    })

    if (!result.isConfirmed) {
      return
    }

    toggleStatus(client)
  }

  return (
    <Div>
      <Card
        sx={{ width: "fit-content", ml: "auto", borderRadius: "3rem", mb: 2 }}
      >
        <JumboSearch
          sx={{ width: 300 }}
          placeholder="Buscar por clientes"
          onChange={searchHandler}
        />
      </Card>

      {!clients && loadingState.clients ? (
        <LoadingMessage message='Carregando clientes'/>
      ) : (
        <Card sx={{ p: 2 }}>
          {clients?.length ? (
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              disableColumnMenu
              density="comfortable"
              disableRowSelectionOnClick
              rows={clients}
              columns={getColumns({
                onEdit: editHandler,
                onToggleStatus: toggleStatusHandler,
              })}
              initialState={{
                pagination: {
                  paginationModel: { page: page - 1, pageSize },
                },
                sorting: sorting
                  ? {
                      sortModel: [
                        { field: sorting.field, sort: sorting.direction },
                      ],
                    }
                  : undefined,
              }}
              pageSizeOptions={[10, 25, 50]}
              onPaginationModelChange={(model) => {
                setPage(model.page + 1)
                setPageSize(model.pageSize)
              }}
              loading={loadingState.clients}
              rowCount={totalClients}
              filterMode="server"
              sortingMode="server"
              paginationMode="server"
              onSortModelChange={sortHandler}
            />
          ) : null}
          {!clients?.length && <NoRowsMessage/>}
        </Card>
      )}
    </Div>
  )
}

export default ClientsListPage
