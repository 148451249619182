export const revenueHistory = [
  { month: "Jan", profit: 0, revenue: 400 },
  { month: "Feb", profit: 150, revenue: 350 },
  { month: "Mar", profit: 100, revenue: 0 },
  { month: "Apr", profit: 300, revenue: 50 },
  { month: "May", profit: 100, revenue: 300 },
  { month: "Jun", profit: 100, revenue: 450 },
  { month: "Jul", profit: 150, revenue: 450 },
  { month: "Aug", profit: 150, revenue: 20 },
  { month: "Sep", profit: 200, revenue: 50 },
  { month: "Oct", profit: 40, revenue: 400 },
  { month: "Nov", profit: 60, revenue: 50 },
  { month: "Dec", profit: 0, revenue: 400 },
]
