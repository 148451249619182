import React, { useState } from "react"
import { Alert, Card, CardContent, Typography } from "@mui/material"
import Link from "@mui/material/Link"
import Div from "@jumbo/shared/Div"
import { alpha } from "@mui/material/styles"
import * as yup from "yup"
import { Form, Formik } from "formik"
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField"
import LoadingButton from "@mui/lab/LoadingButton"
import { useNavigate } from "react-router-dom"
import useJumboAuth from "@jumbo/hooks/useJumboAuth"
import authServices from "app/services/auth-services"
import PasswordField from "app/components/shared/PasswordField"

const validationSchema = yup.object({
  email: yup
    .string("Insira um e-mail")
    .email("E-mail inválido")
    .required("O e-mail é obrigatório"),
  password: yup.string("Insira a senha").required("A senha é obrigatória"),
})

const LoginPage = () => {
  const [error, setError] = useState("")

  const navigate = useNavigate()
  const { setAuthToken } = useJumboAuth()

  const onSignIn = async (email, password) => {
    try {
      const { token } = await authServices.signIn({ email, password })

      if (!token) {
        throw new Error()
      }

      setError("")
      setAuthToken(token)

      navigate("/")
    } catch {
      setError("E-mail e/ou senha inválidos")
    }
  }

  return (
    <Div
      sx={{
        width: 720,
        maxWidth: "100%",
        margin: "auto",
        p: 4,
      }}
    >
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            flex: "0 1 300px",
            position: "relative",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: alpha("#0267a0", 0.65),
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: "100%",
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={"h3"}
                color={"inherit"}
                fontWeight={500}
                mb={3}
              >
                Entrar
              </Typography>
              <Typography variant={"body1"} mb={2}>
                Ao entrar, você terá acesso a todas as funcionalidades do
                FretApp
              </Typography>
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true)
              await onSignIn(data.email, data.password)
              setSubmitting(false)
            }}
            initialValues={{
              email: "",
              password: "",
            }}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form
                data-testid="login-form"
                style={{ textAlign: "left" }}
                noValidate
                autoComplete="off"
                onChange={setError.bind(null, "")}
              >
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField
                    fullWidth
                    name="email"
                    label="E-mail"
                    InputLabelProps={{ htmlFor: "email" }}
                    InputProps={{ id: "email" }}
                  />
                </Div>

                <Div sx={{ mt: 1, mb: 3 }}>
                  <PasswordField
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    InputLabelProps={{ htmlFor: "password" }}
                    InputProps={{ id: "password" }}
                  />
                </Div>

                {error ? (
                  <Alert
                    severity="error"
                    sx={{ mb: 3 }}
                    data-testid="error-alert"
                  >
                    E-mail e/ou senha inválidos
                  </Alert>
                ) : null}

                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                  data-testid="submit-button"
                >
                  Entrar
                </LoadingButton>
                <Typography variant={"body1"}>
                  <Link
                    href={"/auth/forgot-password"}
                    color={"inherit"}
                    underline={"none"}
                  >
                    Esqueceu sua senha? Recupere clicando aqui
                  </Link>
                </Typography>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Div>
  )
}

export default LoginPage
