const { createContext } = require("react")

export const FormContext = createContext({
  readOnly: false,
})

const FormCtx = ({ readOnly, children }) => {
  return (
    <FormContext.Provider value={{ readOnly }}>{children}</FormContext.Provider>
  )
}

export default FormCtx
