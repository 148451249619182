import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField"
import { LoadingButton } from "@mui/lab"
import {
  Card,
  Grid,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import EditableAvatar from "app/components/shared/EditableAvatar"
import LoadingMessage from "app/components/shared/LoadingMessage"
import NotFoundMessage from "app/components/shared/NotFoundMessage"
import useClients from "app/hooks/useClients"
import { Form, Formik } from "formik"
import { useEffect } from "react"
import InputMask from "react-input-mask"
import { useParams } from "react-router-dom"
import { validationSchema } from "./utils"

const EditClientPage = () => {
  const { client, getClient, loadingState, editClient } = useClients()
  const { id: clientId } = useParams()

  const editHandler = async (data) => {
    const result = await editClient(data)

    await getClient(clientId)

    return result
  }

  useEffect(() => {
    getClient(clientId)
  }, [getClient, clientId])

  if (!client) {
    if (loadingState.client) {
      return <LoadingMessage message="Obtendo dados do cliente..." />
    } else {
      return <NotFoundMessage message="O cliente não foi encontrado." />
    }
  }

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h1">Alterar cliente</Typography>

      <Formik
        initialValues={client || {}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true)

          const result = await editHandler(values)

          if (result) {
            resetForm({ values })
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
          isValid,
          dirty,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Stack alignItems="center" sx={{ my: 2 }}>
              <EditableAvatar
                onChange={handleChange}
                initialSource={values.avatar}
                name="avatar"
                setValue={setFieldValue.bind(null, "avatar")}
              />
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <JumboTextField required fullWidth name="name" label="Nome" />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputMask
                  mask="999.999.999-99"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.personalId}
                  alwaysShowMask={false}
                  maskChar=""
                >
                  {() => (
                    <TextField
                      type="text"
                      label="CPF"
                      name="personalId"
                      fullWidth
                      variant="outlined"
                      helperText={touched.personalId ? errors.personalId : ""}
                      error={touched.personalId && Boolean(errors.personalId)}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} md={6}>
                <JumboTextField
                  required
                  fullWidth
                  name="email"
                  label="E-mail"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <InputMask
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  mask={
                    values.phone.length <= 14
                      ? "(99) 9999-9999?"
                      : "(99) 99999-9999"
                  }
                  maskChar=""
                  formatChars={{ 9: "[0-9]", "?": "[0-9 ]" }}
                >
                  {() => (
                    <TextField
                      type="text"
                      label="Telefone"
                      name="phone"
                      fullWidth
                      variant="outlined"
                      helperText={touched.phone ? errors.phone : ""}
                      error={touched.phone && Boolean(errors.phone)}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} md={3}>
                <JumboTextField
                  required
                  fullWidth
                  select
                  name="status"
                  label="Status"
                >
                  <MenuItem value="active">Ativo</MenuItem>
                  <MenuItem value="inactive">Inativo</MenuItem>
                </JumboTextField>
              </Grid>
            </Grid>

            <Stack
              direction="row"
              width="100%"
              spacing={2}
              justifyContent="flex-end"
              sx={{ mt: 2 }}
            >
              <Link href={isSubmitting ? "#" : "/clients/list"}>
                <LoadingButton disabled={isSubmitting}>Cancelar</LoadingButton>
              </Link>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isSubmitting}
                disabled={!dirty || !isValid}
              >
                Salvar
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default EditClientPage
