export const salesHistoryWithPast = [
  { name: "J", current: 4000, past: 800 },
  { name: "F", current: 1000, past: 4200 },
  { name: "M", current: 1800, past: 800 },
  { name: "A", current: 2800, past: 1400 },
  { name: "M", current: 2200, past: 2200 },
  { name: "J", current: 3000, past: 1500 },
  { name: "J", current: 5000, past: 1200 },
  { name: "A", current: 4000, past: 1000 },
  { name: "S", current: 2000, past: 1500 },
  { name: "O", current: 1300, past: 3000 },
  { name: "N", current: 1500, past: 800 },
  { name: "D", current: 2000, past: 600 },
]
