import driverServices from "app/services/driver-services"
import { useCallback } from "react"
import { useMutation, useQueryClient } from "react-query"
import useFeedback from "./useFeedback"

const useDriverUpdate = () => {
  const { showToast } = useFeedback()
  const queryClient = useQueryClient()
  const { mutateAsync: changeDriverStatus, isLoading: isChangingStatus } =
    useMutation("drivers", {
      mutationFn: driverServices.changeApproval,
      onError: () => {
        showToast({
          icon: "error",
          title: "Não foi possível alterar o status do motorista no momento",
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["drivers"])
        showToast({
          icon: "success",
          title: "Status do motorista atualizado com sucesso!",
        })
      },
    })

  const { mutateAsync: updateDriver, isLoading: isUpdatingDriver } =
    useMutation(driverServices.editDriver, {
      onSuccess: () => {
        queryClient.invalidateQueries(["drivers"])
        showToast({
          icon: "success",
          title: "Motorista atualizado com sucesso!",
        })
      },
      onError: () => {
        showToast({
          icon: "error",
          title: "Não foi possível editar os dados deste motorista no momento",
        })
      },
    })

  const approveDriver = useCallback(
    (id) => {
      return changeDriverStatus({
        id,

        status: "approved",
      })
    },
    [changeDriverStatus],
  )

  const refuseDriver = useCallback(
    (id, reason) => {
      return changeDriverStatus({
        id,
        status: "refused",
        reason,
      })
    },
    [changeDriverStatus],
  )

  return {
    isChangingStatus,
    refuseDriver,
    approveDriver,
    updateDriver,
    isUpdatingDriver,
  }
}

export default useDriverUpdate
