import { transformBodyIntoFormData } from "app/utils/appHelpers"
import { formatCPF, formatPhone, getOnlyNumbers } from "app/utils/format"
import jwtAuthAxios from "./auth/jwtAuth"

const clientServices = {}

clientServices.getClient = async (id) => {
  const {
    data: { usuario: data },
  } = await jwtAuthAxios.get(`/usuarios/${id}`)

  return decodeClient(data)
}

clientServices.editClient = async (client) => {
  const parsedData = encodeClient(client)

  const { data } = await jwtAuthAxios.post(
    `/admin/usuarios/${client.id}`,
    parsedData,
  )

  return data
}

clientServices.getClients = async ({ pageSize, page, searchTerm, sorting }) => {
  const params = {
    perPage: pageSize,
    page,
  }

  if (searchTerm) {
    params.field = searchTerm.replace(/[^0-9a-zA-Z]/g, "")
  }

  if (sorting) {
    params.orderBy =
      {
        name: "nome",
        personalId: "cpf",
        phone: "telefone",
      }[sorting.field] || sorting.field
    params.orderDirection = sorting.direction
  }

  const { data } = await jwtAuthAxios.get(`/clientes`, { params })

  return {
    clients: data.usuarios.map((client) => ({
      id: client.id,
      name: client.nome,
      personalId: client.cpf,
      phone: client.telefone,
      email: client.email,
      status: client.status === "ativo" ? "active" : "inactive",
    })),
    total: data.paginationProps.total,
  }
}

clientServices.toggleClientStatus = async ({ id, status }) => {
  const parsedStatus = status === "active" ? "ativo" : "inativo"

  const { data } = await jwtAuthAxios.put(`/admin/usuarios/${id}`, {
    status: parsedStatus,
  })

  return data
}

const decodeClient = (client) => {
  const { id, nome, email, foto, telefone, cpf, status } = client
  return {
    id: id,
    name: nome,
    email: email,
    avatar: foto,
    phone: formatPhone(telefone),
    personalId: formatCPF(cpf),
    status: status === "ativo" ? "active" : "inactive",
  }
}

const encodeClient = (client) => {
  const parsedData = {
    cpf: getOnlyNumbers(client.personalId),
    telefone: getOnlyNumbers(client.phone),
    nome: client.name,
    email: client.email,
    status: client.status === "active" ? "ativo" : "inativo",
    foto: client.avatar,
  }

  return transformBodyIntoFormData(parsedData)
}

export default clientServices
