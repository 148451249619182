import { Group, LocalShipping, Timeline } from "@mui/icons-material"

const menus = [
  {
    label: "sidebar.menu.home",
    type: "section",
    children: [
      {
        uri: "/clients/list",
        label: "Clientes",
        type: "nav-item",
        icon: <Group sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/drivers/list",
        label: "Motoristas",
        type: "nav-item",
        icon: <LocalShipping sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/history/list",
        label: "Histórico",
        type: "nav-item",
        icon: <Timeline sx={{ fontSize: 20 }} />,
      },
    ],
  },
]

export default menus
