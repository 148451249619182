import * as React from "react"
import Stack from "@mui/material/Stack"
import Slider from "@mui/material/Slider"
import JumboDemoCard from "@jumbo/components/JumboDemoCard"
import code from "../Sliders/demo-code/vertical-slider.txt"

const marks = [
  {
    value: 0,
    label: "0°C",
  },
  {
    value: 20,
    label: "20°C",
  },
  {
    value: 37,
    label: "37°C",
  },
  {
    value: 100,
    label: "100°C",
  },
]
const valuetext = (value) => {
  return `${value}°C`
}
const VerticalSlider = () => {
  return (
    <JumboDemoCard
      title={"Vertical sliders"}
      demoCode={code}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Stack sx={{ height: 300 }} spacing={1} direction="row">
        <Slider
          aria-label="Temperature"
          orientation="vertical"
          getAriaValueText={valuetext}
          defaultValue={30}
        />
        <Slider
          aria-label="Temperature"
          orientation="vertical"
          defaultValue={30}
          disabled
        />
        <Slider
          getAriaLabel={() => "Temperature"}
          orientation="vertical"
          getAriaValueText={valuetext}
          defaultValue={[20, 37]}
          marks={marks}
        />
      </Stack>
    </JumboDemoCard>
  )
}
export default VerticalSlider
