import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField"
import {
  Checkbox,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material"
import { Form, Formik, isFunction } from "formik"
import BankSelector from "../DriversList/BankSelector"
import DropzoneImage from "../shared/DropzoneImage"
import EditableAvatar from "../shared/EditableAvatar"
import FormCtx from "../shared/FormCtx"
import { useState } from "react"
import * as yup from "yup"

const EditDriverForm = ({
  validationSchema,
  initialValues,
  readOnly,
  onUpdateDrivers,
  children,
}) => {
  const [parsedValidationSchema, setParsedValidationSchema] = useState(
    readOnly
      ? undefined
      : yup
          .object()
          .shape(
            initialValues.addressWithoutNumber
              ? { ...validationSchema.fields, number: null }
              : validationSchema.fields,
            validationSchema.edges,
          ),
  )

  const addressNumberExistenceChangeHandler = ({
    setFieldValue,
    values,
    setFieldTouched,
  }) => {
    if (values.addressWithoutNumber) {
      setParsedValidationSchema(
        yup.object().shape(validationSchema.fields, validationSchema.edges),
      )
    } else {
      setParsedValidationSchema(
        yup
          .object()
          .shape(
            { ...validationSchema.fields, number: null },
            validationSchema.edges,
          ),
      )
      setFieldValue("number", "")
      setFieldTouched("number", false)
    }

    setFieldValue("addressWithoutNumber", !values.addressWithoutNumber)
  }

  return (
    <Formik
      initialValues={initialValues || {}}
      onSubmit={(values, { resetForm }) => {
        onUpdateDrivers(values)

        resetForm({ values })
      }}
      validationSchema={parsedValidationSchema}
    >
      {({
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
      }) => (
        <FormCtx readOnly={readOnly}>
          <Form noValidate autoComplete="off">
            <Stack sx={{ gap: 2 }}>
              <Stack alignItems="center" sx={{ my: 2 }}>
                <EditableAvatar
                  onChange={handleChange}
                  initialSource={values.avatar}
                  name="avatar"
                  setValue={setFieldValue.bind(null, "avatar")}
                />
              </Stack>

              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <JumboTextField required fullWidth name="name" label="Nome" />
                </Grid>

                <Grid item xs={12} md={4}>
                  <JumboTextField
                    type="text"
                    label="CPF"
                    name="personalId"
                    fullWidth
                    variant="outlined"
                    helperText={touched.personalId ? errors.personalId : ""}
                    error={touched.personalId && Boolean(errors.personalId)}
                    mask="000.000.000-00"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <JumboTextField
                    required
                    fullWidth
                    name="email"
                    label="E-mail"
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <JumboTextField
                    type="text"
                    label="Telefone"
                    name="phone"
                    fullWidth
                    variant="outlined"
                    helperText={touched.phone ? errors.phone : ""}
                    error={touched.phone && Boolean(errors.phone)}
                    mask={["(00) 0000-0000", "(00) 00000-0000"]}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <JumboTextField
                    required
                    fullWidth
                    select
                    name="status"
                    label="Status"
                  >
                    <MenuItem value="active">Ativo</MenuItem>
                    <MenuItem value="inactive">Inativo</MenuItem>
                  </JumboTextField>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">Dados bancários</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <BankSelector
                    required={[
                      values.pixKey,
                      values.pixKeyType,
                      values.agency,
                      values.account,
                    ].some(Boolean)}
                    onChange={(val) => setFieldValue("bank", val)}
                    value={values.bank}
                    error={touched.bank && errors.bank}
                    onBlur={() => setFieldTouched("bank", true)}
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <JumboTextField
                    required={[
                      values.pixKey,
                      values.pixKeyType,
                      values.bank,
                      values.account,
                    ].some(Boolean)}
                    fullWidth
                    name="agency"
                    label="Agência"
                    mask="0000-0"
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <JumboTextField
                    required={[
                      values.pixKey,
                      values.pixKeyType,
                      values.agency,
                      values.bank,
                    ].some(Boolean)}
                    fullWidth
                    name="account"
                    mask={/^((\d){0,11}(-)?(\d)?)$/}
                    label="Conta"
                  />
                </Grid>

                <Grid item xs={4}>
                  <JumboTextField
                    select
                    name="pixKeyType"
                    label="Tipo"
                    required={[
                      values.pixKey,
                      values.bank,
                      values.agency,
                      values.account,
                    ].some(Boolean)}
                    fullWidth
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    <MenuItem value="telefone">Celular</MenuItem>
                    <MenuItem value="aleatorio">Chave aleatória</MenuItem>
                    <MenuItem value="cpf">CPF</MenuItem>
                    <MenuItem value="email">E-mail</MenuItem>
                  </JumboTextField>
                </Grid>

                <Grid item xs={8}>
                  <JumboTextField
                    type="text"
                    label={
                      !!values.pixKeyType
                        ? "Chave"
                        : "Selecione um tipo de chave primeiro"
                    }
                    name="pixKey"
                    fullWidth
                    variant="outlined"
                    required={[
                      values.bank,
                      values.pixKeyType,
                      values.agency,
                      values.account,
                    ].some(Boolean)}
                    disabled={!values.pixKeyType}
                    mask={
                      {
                        cpf: "000.000.000-00",
                        aleatorio: "AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA",
                        telefone: ["(00) 0000-0000", "(00) 00000-0000"],
                      }[values.pixKeyType]
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">Dados de endereço</Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <JumboTextField
                    required
                    fullWidth
                    name="postalCode"
                    label="CEP"
                    mask="00000-000"
                  />
                </Grid>
                <Grid item sm={8} xs={12}>
                  <JumboTextField
                    required
                    fullWidth
                    name="address"
                    label="Endereço"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" alignItems="flex-start" gap="0.5rem">
                    <JumboTextField
                      required={!values.addressWithoutNumber}
                      fullWidth
                      name="number"
                      label="Número"
                      disabled={values.addressWithoutNumber}
                    />
                    <Stack
                      m="0.25rem 1rem 0 0"
                      alignItems="center"
                      direction="row"
                    >
                      <Checkbox
                        checked={values.addressWithoutNumber}
                        onChange={addressNumberExistenceChangeHandler.bind(
                          null,
                          {
                            setFieldValue,
                            values,
                            setFieldTouched,
                          },
                        )}
                      />
                      <Typography>Sem&nbsp;número</Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <JumboTextField
                    required
                    fullWidth
                    name="city"
                    label="Cidade"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">Dados do veículo</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <JumboTextField
                    required
                    fullWidth
                    name="cnh"
                    label="CNH"
                    mask="00000000000"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <JumboTextField
                    required
                    fullWidth
                    name="licensePlate"
                    label="Placa"
                    mask="AAAAAAA"
                  />
                </Grid>
                <Grid item xs={6}>
                  <JumboTextField
                    required
                    fullWidth
                    name="model"
                    label="Modelo"
                  />
                </Grid>
                <Grid item xs={6}>
                  <JumboTextField
                    required
                    fullWidth
                    select
                    name="vehicleType"
                    label="Tipo"
                    defaultValue=""
                  >
                    <MenuItem value="largeTruck">Caminhão grande</MenuItem>
                    <MenuItem value="smallTruck">Caminhão pequeno</MenuItem>
                    <MenuItem value="car">Carro</MenuItem>
                    <MenuItem value="bike">Moto</MenuItem>
                    <MenuItem value="pickup">Pick-up</MenuItem>
                  </JumboTextField>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <FormLabel>Imagem da CNH *</FormLabel>
                  <DropzoneImage
                    image={values.cnhImage}
                    onChangeImage={(img) => {
                      setFieldValue("cnhImage", img)
                    }}
                    error={touched.cnhImage && errors.cnhImage}
                    onBlur={setFieldTouched.bind(null, "cnhImage", true)}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <FormLabel>Imagem do CRLV *</FormLabel>
                  <DropzoneImage
                    image={values.crlvImage}
                    onChangeImage={setFieldValue.bind(null, "crlvImage")}
                    error={touched.crlvImage && errors.crlvImage}
                    onBlur={setFieldTouched.bind(null, "crlvImage", true)}
                  />
                </Grid>
              </Grid>

              {isFunction(children)
                ? children({ dirty, isValid, isSubmitting, errors })
                : children}
            </Stack>
          </Form>
        </FormCtx>
      )}
    </Formik>
  )
}

export default EditDriverForm
