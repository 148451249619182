import { useTheme } from "@emotion/react"
import { Clear, Download } from "@mui/icons-material"
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material"
import useFeedback from "app/hooks/useFeedback"
import useFormCtx from "app/hooks/useFormCtx"
import jwtAuthAxios from "app/services/auth/jwtAuth"
import { useCallback } from "react"
import { useDropzone } from "react-dropzone"

const imageHoverStyles = {
  cursor: "pointer",
  transition: "100ms all ease-in",
  opacity: 0.2,
}

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
    
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const DropzoneImage = ({ image, onChangeImage, onBlur, error }) => {
  const { readOnly } = useFormCtx()
  const onDrop = useCallback(
    (files) => {
      if (files.length > 0) {
        onChangeImage(files[0])
      }
      onBlur()
    },
    [onChangeImage, onBlur],
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    onFileDialogCancel: onBlur,
    disabled: readOnly,
  })
  const { palette } = useTheme()
  const { showToast } = useFeedback()
  const downloadFile = async () => {
    let url
    let extension = 'png'

    if (image.url) {
      try {
        const response = await jwtAuthAxios.get(image.url)
        const base64String = response.data.content
        const imageContent = b64toBlob(base64String.split(',')[1])

        extension = base64String.substring(base64String.indexOf('/') + 1, base64String.indexOf(';base64'));
        url = window.URL.createObjectURL(imageContent)
      } catch {
        showToast({
          icon: "error",
          title: "Não foi possível fazer o download deste arquivo",
        })
      }
    } else {
      url = window.URL.createObjectURL(image)
    }

    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    a.setAttribute("download", `${image.name}.${extension}`)
    a.target = "_blank"
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const clearFile = () => {
    onChangeImage(null)
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "1",
        height: 125,
        alignItems: "center",
        borderRadius: 4,
        justifyContent: "center",
      }}
    >
      {image ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: 0.7,
            minWidth: 150,
            padding: 2,
            borderRadius: 4,
            bgcolor: palette.primary.main + "14",
          }}
        >
          <Typography noWrap>{image.name}</Typography>
          <Stack direction="row">
            {!readOnly && (
              <IconButton onClick={clearFile}>
                <Clear />
              </IconButton>
            )}
            <IconButton onClick={downloadFile}>
              <Download />
            </IconButton>
          </Stack>
        </Box>
      ) : (
        <Paper
          sx={{
            borderWidth: 3,
            borderStyle: "dashed",
            borderColor: error ? "error.main" : "divider",
            width: 1,
            height: 1,
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.default",
            textAlign: "center",
            justifyContent: "center",
            borderRadius: 4,
            overflow: "hidden",

            "&:hover": {
              cursor: "pointer",
            },

            "&:hover img": imageHoverStyles,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} onBlur={onBlur} />
          <Box
            sx={{
              color: error ? "error.main" : undefined,
            }}
          >
            {isDragActive ? (
              <Typography>Solte o arquivo aqui para enviar</Typography>
            ) : (
              <Typography>Arraste uma imagem ou clique aqui</Typography>
            )}
          </Box>
        </Paper>
      )}
    </Box>
  )
}

export default DropzoneImage
