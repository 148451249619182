export const salesChartData = {
  monthly: [
    { hKey: "Jan", Max: 300, Min: 150 },
    { hKey: "Feb", Max: 400, Min: 200 },
    { hKey: "Mar", Max: 350, Min: 175 },
    { hKey: "Apr", Max: 500, Min: 250 },
    { hKey: "May", Max: 300, Min: 150 },
    { hKey: "Jun", Max: 450, Min: 225 },
    { hKey: "Jul", Max: 500, Min: 250 },
    { hKey: "Aug", Max: 650, Min: 375 },
    { hKey: "Sep", Max: 500, Min: 300 },
    { hKey: "Oct", Max: 700, Min: 350 },
    { hKey: "Nov", Max: 450, Min: 225 },
    { hKey: "Dec", Max: 300, Min: 200 },
  ],
  yearly: [
    { hKey: "2011", Max: 250, Min: 125 },
    { hKey: "2012", Max: 350, Min: 175 },
    { hKey: "2013", Max: 320, Min: 160 },
    { hKey: "2014", Max: 400, Min: 200 },
    { hKey: "2015", Max: 550, Min: 275 },
    { hKey: "2016", Max: 450, Min: 225 },
    { hKey: "2017", Max: 600, Min: 375 },
    { hKey: "2018", Max: 400, Min: 300 },
    { hKey: "2019", Max: 500, Min: 350 },
    { hKey: "2020", Max: 650, Min: 225 },
    { hKey: "2021", Max: 450, Min: 300 },
  ],
}
