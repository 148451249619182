export const taskCategories = [
  { id: 12, label: "Personal Tasks", slug: "personal" },
  { id: 13, label: "Professional Tasks", slug: "professional" },
  { id: 14, label: "Extra Tasks", slug: "extra" },
]

export const tasksList = [
  {
    id: 1,
    title: "Meet Dr. Alan at 05:00 PM",
    description: "Simply dummy text of the printing and typesetting industry.",
    unreadComments: 2,
    isCompleted: true,
    category: "personal",
  },
  {
    id: 2,
    title: "Scrum call with the dev team",
    description: "Text of the printing and typesetting industry.",
    unreadComments: 4,
    isCompleted: false,
    category: "professional",
  },
  {
    id: 3,
    title: "Write SRS for Cling app",
    description: "Printing and typesetting industry simply dummy text.",
    unreadComments: 0,
    isCompleted: true,
    category: "extra",
  },
  {
    id: 4,
    title: "Work on personal website",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    unreadComments: 5,
    isCompleted: false,
    category: "personal",
  },
  {
    id: 5,
    title: "Release Jumbo iOS app 5.x",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    unreadComments: 2,
    isCompleted: false,
    category: "professional",
  },
  {
    id: 6,
    title: "Take the team out for dinner",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    unreadComments: 1,
    isCompleted: false,
    category: "extra",
  },
  {
    id: 7,
    title: "Get together with old college friends",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    unreadComments: 0,
    isCompleted: true,
    category: "personal",
  },
  {
    id: 8,
    title: "Team Meeting",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    unreadComments: 2,
    isCompleted: true,
    category: "professional",
  },
  {
    id: 9,
    title: "Book a flight to London next week",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    unreadComments: 2,
    isCompleted: false,
    category: "extra",
  },
  {
    id: 10,
    title: "Home cleaning and car washing",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    unreadComments: 0,
    isCompleted: false,
    category: "personal",
  },
  {
    id: 11,
    title: "Read a new book on spiritualism",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    unreadComments: 2,
    isCompleted: true,
    category: "extra",
  },
  {
    id: 12,
    title: "Setup project Dormet on asana",
    description:
      "Lorem ipsum is simply dummy text of the printing and typesetting industry.",
    unreadComments: 0,
    isCompleted: false,
    category: "professional",
  },
]
