import React from "react"
import JumboDemoCard from "@jumbo/components/JumboDemoCard"
import TextField from "@mui/material/TextField"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import code from "../Pickers/demo-code/date-time-picker.txt"

const DateAndTimePickers = () => {
  const [value, setValue] = React.useState(new Date("2022-06-04T21:11:54"))
  return (
    <JumboDemoCard
      title={"Date & Time Pickers"}
      demoCode={code}
      wrapperSx={{ backgroundColor: "background.color", pt: 0 }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label="Date&Time picker"
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </JumboDemoCard>
  )
}

export default DateAndTimePickers
