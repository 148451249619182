import Div from "@jumbo/shared/Div"
import { Check, Clear, EditOutlined, Visibility } from "@mui/icons-material"
import { MenuItem, Typography } from "@mui/material"
import BaseDriverMenu from "./BaseDriverMenu"

const PendingDriverMenu = ({ onEdit, onApprove, onDisapprove, onView }) => {
  return (
    <BaseDriverMenu>
      {onEdit && (
        <MenuItem onClick={onEdit}>
          <Div sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <EditOutlined sx={{ fontSize: "1rem" }} />
            <Typography>Alterar</Typography>
          </Div>
        </MenuItem>
      )}
      {onView && (
        <MenuItem onClick={onView}>
          <Div sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Visibility sx={{ fontSize: "1rem" }} />
            <Typography>Visualizar</Typography>
          </Div>
        </MenuItem>
      )}
      <MenuItem onClick={onApprove}>
        <Div sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <Check sx={{ fontSize: "1rem" }} />
          <Typography>Aprovar</Typography>
        </Div>
      </MenuItem>
      {onDisapprove && (
        <MenuItem onClick={onDisapprove}>
          <Div sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Clear sx={{ fontSize: "1rem" }} />
            <Typography>Reprovar</Typography>
          </Div>
        </MenuItem>
      )}
    </BaseDriverMenu>
  )
}

export default PendingDriverMenu
